import crypto from "crypto-js";
import Utf8 from "crypto-js/enc-utf8";

export function getEncryptedString(str: string) {
  const ecrypted = crypto.AES.encrypt(str, process.env.VUE_APP_AES_TOKEN);
  return ecrypted.toString();
}

export function getDecryptedString(encrypted: string) {
  const bytes = crypto.AES.decrypt(encrypted, process.env.VUE_APP_AES_TOKEN);
  return bytes.toString(Utf8);
}
