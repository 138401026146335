export type TalentArgs = {
  displayName: string;
  eventDate: number;
  genre: Array<number>;
  images: Array<MainImage>;
  introduction: string;
  talentID: string;
  liveStatus: number;
};

export type ImageArgs = {
  position: number;
  url: string;
};

export class MainImage {
  position: number;
  url: string;

  constructor(l: ImageArgs) {
    this.position = l.position;
    this.url = l.url;
  }
}

export default class Talent {
  displayName: string;
  eventDate: number;
  genre: Array<number>;
  images: Array<MainImage>;
  introduction: string;
  talentID: string;
  liveStatus: number;

  constructor(l: TalentArgs) {
    this.displayName = l.displayName;
    this.eventDate = l.eventDate;
    this.genre = l.genre;
    this.images = l.images;
    this.introduction = l.introduction;
    this.talentID = l.talentID;
    this.liveStatus = l.liveStatus;
  }

  get genreForDisplay() {
    if (this.genre != undefined) {
      switch (this.genre[0]) {
        case 0:
          return "その他";
        case 1:
          return "モデル";
        case 2:
          return "俳優";
        case 3:
          return "女優";
        case 4:
          return "YouTuber";
        case 5:
          return "ライバー";
        case 6:
          return "インスタグラマー";
        case 7:
          return "TikToker";
        case 8:
          return "アイドル";
        case 9:
          return "コスプレイヤー";
        case 10:
          return "声優";
        case 11:
          return "タレント";
        case 12:
          return "アーティスト";
        case 13:
          return "お笑い芸人";
        case 14:
          return "占い師";
        case 15:
          return "その他";
        case 16:
          return "インフルエンサー";
      }
    } else {
      return "インフルエンサー"
    }
  }

  get convertUnixToDate() {
    const d = new Date(this.eventDate * 1000);
    const MM = ("0" + (d.getMonth() + 1)).slice(-2);
    const dd = ("0" + d.getDate()).slice(-2);
    const hh = ("0" + d.getHours()).slice(-2);
    const mm = ("0" + d.getMinutes()).slice(-2);
    return MM + "/" + dd + " " + hh + ":" + mm + "-";
  }

  get formatDisplayNameOfTalkFutureTalents() {
    if (this.displayName.length >= 9) {
      const shortDisplayName = this.displayName.slice(0, 7) + "...";
      return shortDisplayName;
    } else {
      return this.displayName;
    }
  }

  get mainImage() {
    if (this.images.length >= 1) {
      return this.images[0].url;
    }
    return "";
  }

  get formatDisplayName() {
    if (window.outerWidth < 375 && this.displayName.length >= 9) {
      const shortDisplayName = this.displayName.slice(0, 7) + "...";
      return shortDisplayName;
    } else if (window.outerWidth >= 375 && this.displayName.length >= 10) {
      const shortDisplayName = this.displayName.slice(0, 9) + "...";
      return shortDisplayName;
    } else {
      return this.displayName;
    }
  }
}
