
import { defineComponent } from "vue";import TalentList from "./Studio15TalentList.vue";
import Usecase from "@/components/common_presentational/Usecase.vue";

export default defineComponent({
  name: "Studio15Screen",
  components: {
    TalentList,
    Usecase
  },
});
