import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import * as api from "@/infra/api";
import * as local from "@/infra/local";
import client from "@/infra/api/clients";
import { repo } from "@/infra/api/injects";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faPhone,
  faTimes,
  faChevronDown,
  faEllipsisV,
  faVideo,
  faVideoSlash,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSearch, faPhone, faClock, faTimes, faChevronDown, faEllipsisV, faVideo, faVideoSlash, faWindowClose);

const sessionRepo = new api.LocalSessionRepository();
const value = ref({
  TalentRepository: new api.APITalentRepository(client, sessionRepo),
  FanRepository: new api.APIFanRepository(client, sessionRepo),
  SessionRepository: sessionRepo,
  WalletManagerRepository: new api.APIWalletManagerRepository(client, sessionRepo),
  EventRepository: new api.APIEventRepository(client, sessionRepo),
  LocalProductRepository: new local.ProductRepository(),
  ReservationRepository: new api.APIReservationRepository(client, sessionRepo),
});

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  // repositories.d.tsに定義したRepositoriesをkeyとして読み込む
  .provide(repo, value)
  .mount("#app");
