
import { defineComponent } from 'vue';

export default defineComponent({
  name: "RankingUser",
  props: {
    name: {
      type: String,
      required: true
    },
    score: {
      type: Number,
      required: true
    },
    path: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true
    }
  },
})
