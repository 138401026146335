
import { defineComponent } from "vue";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

export default defineComponent({
  name: "RankingSecond",
  components: {
    Imgix,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  setup() {
    const imageSize: ImageSize = {
      sm: {
        width: 61,
        height: 61,
      },
      md: {
        width: 61,
        height: 61,
      },
      lg: {
        width: 84,
        height: 84,
      },
    };
    return {
      imageSize,
    };
  },
});
