
import Fan from "@/entities/fan";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileIcon",
  props: {
    fan: {
      type: Fan,
      required: true,
      default: null,
    },
  },
  setup(props) {
    const loadImage = () => {
      if (!props.fan.image_uri) {
        return require("@/assets/img/no-image.png");
      }
      return props.fan.image_uri;
    };
    return {
      loadImage,
    };
  },
});
