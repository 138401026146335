
import { defineComponent, h, reactive, onMounted } from "vue";
import FeatureEntity from "@/entities/feature";
import { useRepositories } from "@/infra/api/injects";
import Features from "@/components/presentational/top/Features.vue";

export default defineComponent({
  name: "FeaturesContainer",
  setup() {

    const state = reactive({
      features: [] as FeatureEntity[],
    });

    const repositories = useRepositories()

    async function getFeatures() {
        state.features = await repositories.value.TalentRepository.getFeatures()
    }

    onMounted(async () => {
      await getFeatures()
    })
    return {
      state,
    };
  },
  render() {
    return h(Features, {
      features: this.state.features,
    });
  },
});
