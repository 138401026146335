
import { defineComponent, PropType, reactive } from "vue";
import { useRouter } from "vue-router";
// @ts-ignore
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/components/navigation/navigation.scss";
import Talent from "@/entities/talent";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

SwiperCore.use([Navigation]);

export default defineComponent({
  name: "TalkFutureOfTopScreen",
  components: {
    Swiper,
    SwiperSlide,
    Imgix,
  },

  props: {
    futureTalents: {
      type: Array as PropType<Talent[]>,
      required: true,
    },
  },

  setup(prop) {
    const router = useRouter();

    const imageSize: ImageSize = {
      sm: {
        width: 162,
        height: 216,
      },
      md: {
        width: 160,
        height: 213,
      },
      lg: {
        width: 160,
        height: 213,
      },
    };

    const state = reactive({
      futureTalents: prop.futureTalents,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpointOptions: {
        375: {
          slidesPerView: 2.7,
          spaceBetween: 16,
        },
        430: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        530: {
          slidesPerView: 3.5,
          spaceBetween: 16,
        },
        600: {
          slidesPerView: 4.5,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 5.05,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 6.05,
          spaceBetween: 20,
        },
      },
    });

    async function openTalent(talentID: string) {
      router.push({ name: "TalentInfoScreen", params: { talent_id: talentID } });
    }

    return {
      state,
      openTalent,
      imageSize,
    };
  },
});
