
import { defineComponent, reactive } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
export default defineComponent({
  name: "AppDownloadModal",
  components: {
    BaseRoundRectButton,
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
});
