
import { defineComponent, onMounted, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/OfficeTalentListCard.vue";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";
import Talent from "@/entities/talent";
import { getOfficeTalents } from "@/util/get_office_talents";

export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
    Imgix
  },
  setup() {
    const state = reactive({
      officeTalents: [] as Talent[],
      officeTalentIds: [
         {
          talentID: "1309d8a3-b316-4ca3-a1a7-cc6b6af553e0",
        },
        {
          talentID: "9fcbd884-7eca-46ff-bb67-881556fb6c12",
        },
        {
          talentID: "a5671a6f-09f2-40d7-a890-7c351f547698",
        },
        {
          talentID: "8164008f-5c05-46ed-a04c-27fa4416ed13",
        },
         {
          talentID: "ebc2e1bf-55fc-4e1c-9277-9da3b7d0a813",
        },
        {
          talentID: "aac42944-65fe-4150-a945-9336f24b8a6d",
        },
        {
          talentID: "ebef097a-2620-40d9-9c3a-e030833d4575",
        },
        {
          talentID: "c459de83-2773-49cd-a015-fa529c2a0361",
        },
      ],

    });

    onMounted(async () => {
      try {
        state.officeTalents = await getOfficeTalents(state.officeTalentIds);
      } catch (e) {}
    });

    const imageSize: ImageSize = {
      sm: {
        width: 125,
        height: 175,
      },
      md: {
        width: 150,
        height: 250,
      },
      lg: {
        width: 200,
        height: 300,
      },
    }; 

    return {
      state,
      imageSize
    }
  }
});
