import TalentRepository from "@/repositories/talent_repository";
import TalentEntity, { MainImage } from "@/entities/talent";
import FeatureEntity from "@/entities/feature";
import { FanMeeting, FanMeetingState, FanMeetingStyle, LiveStreamMethodType } from "@/entities/fan_meeting";
import RankingEntity from "@/entities/ranking";
import { RankingUserInfo } from "@/entities/ranking";
import { AxiosInstance } from "axios";
import { FanMeetingAndReserved, ReservationSummaryState } from "@/entities/fan_meeting_and_reserved";
import SessionRepository from "@/repositories/local/session_repository";

export interface TalentResponse {
  display_name?: string;
  genre?: [number];
  images?: [ImageResponse];
  introduction?: string;
  uuid?: string;
}

export interface ImageResponse {
  position: number;
  url?: string;
}

interface Talents {
  fan_meeting: {
    id: number;
    limited_people?: number;
    is_extension?: number;
    is_pre_reservation?: number;
    item_code?: string;
    seconds_per_reservation?: number;
    thumbnail_movie_uri?: string;
    flv_uri?: string;
    style: number;
    streamer_live_stream_method_type: number;
    viewer_live_stream_method_type: number;
    campaign_uuid?: string;
    live_stream_image_uri?: string;
    cheki_price?: number;
    event_date: {
      seconds: number;
    };
    influencer: {
      display_name: string;
      genres: [number];
      images: [ImageResponse];
      introduction: string;
      uuid: string;
      account_type: number;
    };
    // 配信ステータス
    state: number;
    title: string;
    themeId: number;
  };
  is_reserved?: boolean;
  reservation_summary_state: ReservationSummaryStateResponse;
}

interface RankingResponse {
  influencer: TalentResponse;
  points: number;
}

interface LiveStatus {
  fan_meeting: {
    state: number;
  };
}

interface Features {
  image_uri: string;
  webview_uri: string;
}

interface GetTalentResponse {
  influencer: TalentResponse;
  event_date: {
    seconds: number;
  };
  state: number;
}

interface GetTalentsResponse {
  fan_meeting_and_reserveds: Talents[];
  next_page_token: string;
}

interface GetScheduleResponse {
  fan_meeting_and_reserveds: Talents[];
}

interface ReservationSummaryStateResponse {
  wait_seconds?: number;
  is_soldout?: boolean;
  remaining_num?: number;
  wait_num?: number;
}

interface FanMeetingResponse {
  id: string;
}

interface GetFeaturesResponse {
  features: Features[];
}

interface GetRankingResponse {
  weekly_rankings: RankingResponse[];
  monthly_rankings: RankingResponse[];
}

export default class APITalentRepository implements TalentRepository {
  client: AxiosInstance;
  sessionRepo: SessionRepository;

  constructor(client: AxiosInstance, sessionRepo: SessionRepository) {
    this.client = client;
    this.sessionRepo = sessionRepo;
  }

  // APIアドレスを直で叩くためbasehostを設定し切り替え
  basehost = process.env.VUE_APP_BASEHOST;

  // ライバー詳細を取得するAPI
  async getTalentByID(talentID: string): Promise<TalentEntity> {
    return this.client
      .get<GetTalentResponse>(`https://${this.basehost}/v1/influencers/uuid/${talentID}`)
      .then((res) => {
        const r = res.data;
        return new TalentEntity({
          displayName: r.influencer.display_name ?? "",
          genre: r.influencer.genre ?? [],
          images: r.influencer.images?.map((e) => new MainImage({ url: e.url ?? "", position: e.position })) ?? [],
          introduction: r.influencer.introduction ?? "",
          talentID: r.influencer.uuid ?? "",
          liveStatus: r.state,
          eventDate: 0,
        });
      });
  }

  // 任意のライバーに関するスケジュール情報を取得するAPI
  async getScheduleByTalentID(talentID: string): Promise<FanMeetingAndReserved[]> {
    let fanUUID = "";
    await this.sessionRepo.getAuthInfo()
      .then((value) => {
        fanUUID = value.fan_uuid
      })
      .catch((e) => {
        console.log(e)
      })

    const query = `?state=3&fan_uuid=${fanUUID}`
    return this.client
      .get<GetScheduleResponse>(
        `https://${this.basehost}/v2/fan-meetings/influencers/${talentID}${query}`
      )

      .then((res) => {
        const talents: FanMeetingAndReserved[] = [];
        res.data.fan_meeting_and_reserveds.map((r) => {
          talents.push(
            new FanMeetingAndReserved({
              fanMeeting: new FanMeeting({
                id: r.fan_meeting.id,
                limitedPeople: r.fan_meeting.limited_people ?? 0,
                secondsPerReservation: r.fan_meeting.seconds_per_reservation ?? 0,
                state: Object.values(FanMeetingState).find((e) => e === r.fan_meeting.state) ?? 0,
                isPreReservation: r.fan_meeting.is_pre_reservation == 2,
                eventDate: new Date(r.fan_meeting.event_date.seconds * 1000),
                itemCode: r.fan_meeting.item_code ?? "",
                thumbnailMovieUri: "",
                style: Object.values(FanMeetingStyle).find((e) => e === r.fan_meeting.style) ?? 0,
                streamerLiveStreamMethodType:
                  Object.values(LiveStreamMethodType)[
                    Number(r.fan_meeting.streamer_live_stream_method_type?.valueOf())
                  ],
                viewerLiveStreamMethodType:
                  Object.values(LiveStreamMethodType)[Number(r.fan_meeting.viewer_live_stream_method_type?.valueOf())],
                liveStreamImageUri: "",
                chekiPrice: r.fan_meeting.cheki_price ?? 0,
                isExtension: r.fan_meeting.is_extension == 2,
                title: r.fan_meeting.title ?? "",
                themeId: r.fan_meeting.themeId ?? 0,
              }),
              reservationSummaryState: new ReservationSummaryState({
                waitNum: r.reservation_summary_state.wait_num ?? 0,
                waitSeconds: r.reservation_summary_state.wait_seconds ?? 0,
                isSoldOut: r.reservation_summary_state.is_soldout ?? false,
                remainingNum: r.reservation_summary_state.remaining_num ?? 0,
              }),
              isReserved: r.is_reserved ?? false,
            })
          );
        });
        return talents;
      });
  }

  // 任意のライバーが開催中であるミーティングを取得し、あればliveStatusを返すAPI。ミーティングが配列で取得されるので、一応マッピングする。
  async getNowFanMeetingByTalentId(talentID: string): Promise<FanMeetingAndReserved | undefined> {
    const auth = await this.sessionRepo.getAuthInfo();
    return this.client
      .get<GetScheduleResponse>(
        `https://${this.basehost}/v2/fan-meetings/influencers/${talentID}?state=2&fan_uuid=${auth.fan_uuid}`
      )
      .then((res) => {
        if (res.data.fan_meeting_and_reserveds === undefined) {
          return undefined;
        }

        const fanMeetingANdReserved = res.data.fan_meeting_and_reserveds[0];

        return new FanMeetingAndReserved({
          fanMeeting: new FanMeeting({
            id: fanMeetingANdReserved.fan_meeting.id,
            limitedPeople: fanMeetingANdReserved.fan_meeting.limited_people ?? 0,
            secondsPerReservation: fanMeetingANdReserved.fan_meeting.seconds_per_reservation ?? 0,
            state: Object.values(FanMeetingState).find((e) => e === fanMeetingANdReserved.fan_meeting.state) ?? 0,
            isPreReservation: fanMeetingANdReserved.fan_meeting.is_pre_reservation == 2,
            eventDate: new Date(fanMeetingANdReserved.fan_meeting.event_date.seconds * 1000),
            itemCode: fanMeetingANdReserved.fan_meeting.item_code ?? "",
            thumbnailMovieUri: "",
            style: Object.values(FanMeetingStyle).find((e) => e === fanMeetingANdReserved.fan_meeting.style) ?? 0,
            streamerLiveStreamMethodType:
              Object.values(LiveStreamMethodType)[
                Number(fanMeetingANdReserved.fan_meeting.streamer_live_stream_method_type?.valueOf())
              ],
            viewerLiveStreamMethodType: LiveStreamMethodType.unspecified,
            liveStreamImageUri: "",
            chekiPrice: fanMeetingANdReserved.fan_meeting.cheki_price ?? 0,
            isExtension: fanMeetingANdReserved.fan_meeting.is_extension == 2,
            title: fanMeetingANdReserved.fan_meeting.title ?? "",
            themeId: fanMeetingANdReserved.fan_meeting.themeId ?? 0,
          }),
          reservationSummaryState: new ReservationSummaryState({
            waitNum: fanMeetingANdReserved.reservation_summary_state.wait_num ?? 0,
            waitSeconds: fanMeetingANdReserved.reservation_summary_state.wait_seconds ?? 0,
            isSoldOut: fanMeetingANdReserved.reservation_summary_state.is_soldout ?? false,
            remainingNum: fanMeetingANdReserved.reservation_summary_state.remaining_num ?? 0,
          }),
          isReserved: fanMeetingANdReserved.is_reserved ?? false,
        });
      });
  }

  // 開催中のライバーを取得するAPI
  async getNowTalents(nextPageToken: string): Promise<[TalentEntity[], string]> {
    return this.client
      .get<GetTalentsResponse>(`https://${this.basehost}/v1/fan-meetings?state=2&page_token=${nextPageToken}`)
      .then((res) => {
        const talents: TalentEntity[] = [];
        nextPageToken = res.data.next_page_token;
        if (res.data.fan_meeting_and_reserveds != undefined) {
          const filteredTalents = res.data.fan_meeting_and_reserveds.filter(e => (e.fan_meeting.influencer.images != null && e.fan_meeting.influencer.account_type == 1))
          filteredTalents.map((r) => {
            talents.push(
              new TalentEntity({
                displayName: r.fan_meeting.influencer.display_name,
                eventDate: r.fan_meeting.event_date.seconds,
                genre: r.fan_meeting.influencer.genres,
                images:
                  r.fan_meeting.influencer.images?.map(
                    (e) => new MainImage({ url: e.url ?? "", position: e.position })
                  ) ?? [],
                introduction: r.fan_meeting.influencer.introduction,
                talentID: r.fan_meeting.influencer.uuid,
                liveStatus: r.fan_meeting.state,
              })
            );
          });
          return [talents, nextPageToken];
        }
        return [[], ""];
      });
  }

  // 開催予約しているタレントを優先的に、全てのライバーを取得するAPI
  async getFutureTalents(nextPageToken: string): Promise<[TalentEntity[], string]> {
    return this.client
      .get<GetTalentsResponse>(`https://${this.basehost}/v1/fan-meetings?state=3&page_token=${nextPageToken}`)
      .then((res) => {
        const talents: TalentEntity[] = [];
        nextPageToken = res.data.next_page_token;
        const filteredTalents = res.data.fan_meeting_and_reserveds.filter(e => (e.fan_meeting.influencer.images != null && e.fan_meeting.influencer.account_type == 1))
        filteredTalents.map((r) => {
          let eventDate = 0;
          if (r.fan_meeting.state == 3) {
            eventDate = r.fan_meeting.event_date.seconds;
          } else {
            eventDate = 0;
          }
          talents.push(
            new TalentEntity({
              displayName: r.fan_meeting.influencer.display_name,
              eventDate: eventDate,
              genre: r.fan_meeting.influencer.genres,
              images:
                r.fan_meeting.influencer.images?.map(
                  (e) => new MainImage({ url: e.url ?? "", position: e.position })
                ) ?? [],
              introduction: r.fan_meeting.influencer.introduction,
              talentID: r.fan_meeting.influencer.uuid,
              liveStatus: r.fan_meeting.state,
            })
          );
        });
        return [talents, nextPageToken];
      });
  }

  // 人気急上昇を取得するAPI
  async getTalentsByTopic(nextPageToken: string): Promise<[TalentEntity[], string]> {
    return this.client
      .get<GetTalentsResponse>(`https://${this.basehost}/v1/fan-meetings/topic/1?page_token=${nextPageToken}`)
      .then((res) => {
        const talents: TalentEntity[] = [];
        nextPageToken = res.data.next_page_token;
        const filteredTalents = res.data.fan_meeting_and_reserveds.filter(e => (e.fan_meeting.influencer.images != null && e.fan_meeting.influencer.account_type == 1))
        filteredTalents.map((r) => {
          talents.push(
            new TalentEntity({
              displayName: r.fan_meeting.influencer.display_name,
              genre: r.fan_meeting.influencer.genres,
              images:
                r.fan_meeting.influencer.images?.map(
                  (e) => new MainImage({ url: e.url ?? "", position: e.position })
                ) ?? [],
              introduction: r.fan_meeting.influencer.introduction,
              talentID: r.fan_meeting.influencer.uuid,
              liveStatus: r.fan_meeting.state,
              eventDate: 0,
            })
          );
        });
        return [talents, nextPageToken];
      });
  }

  async getAllTalents(nextPageToken: string): Promise<[TalentEntity[], string]> {
    return this.client
      .get<GetTalentsResponse>(`https://${this.basehost}/v1/fan-meetings/all?page_token=${nextPageToken}`)
      .then((res) => {
        const talents: TalentEntity[] = [];
        nextPageToken = res.data.next_page_token;
        const filteredTalents = res.data.fan_meeting_and_reserveds.filter(e => (e.fan_meeting.influencer.images != null && e.fan_meeting.influencer.account_type == 1))
        filteredTalents.map((r) => {
          talents.push(
            new TalentEntity({
              displayName: r.fan_meeting.influencer.display_name,
              genre: r.fan_meeting.influencer.genres,
              images:
                r.fan_meeting.influencer.images?.map(
                  (e) => new MainImage({ url: e.url ?? "", position: e.position })
                ) ?? [],
              introduction: r.fan_meeting.influencer.introduction,
              talentID: r.fan_meeting.influencer.uuid,
              liveStatus: r.fan_meeting.state,
              eventDate: 0,
            })
          );
        });
        return [talents, nextPageToken];
      });
  }

  // アプリバナーを取得するAPI
  async getFeatures(): Promise<FeatureEntity[]> {
    return this.client.get<GetFeaturesResponse>(`https://${this.basehost}/v1/features?page_size=10`).then((res) => {
      const features: FeatureEntity[] = [];
      res.data.features.map((r) => {
        features.push(
          new FeatureEntity({
            imageSrc: r.image_uri,
            url: r.webview_uri,
          })
        );
      });
      return features;
    });
  }

  async getRanking(): Promise<RankingEntity> {
    return this.client.get<GetRankingResponse>(`https://${this.basehost}/v1/ranking`).then((res) => {
      const rankings: RankingEntity = { weekly: [], monthly: [] };
      res.data.monthly_rankings.map((r) => {
        rankings.monthly.push(
          new RankingUserInfo({
            influencer: {
              influencer: new TalentEntity({
                displayName: r.influencer.display_name ?? "",
                genre: r.influencer.genre ?? [],
                images: r.influencer.images?.map((e) => new MainImage({ url: e.url ?? "", position: e.position })) ?? [],
                introduction: r.influencer.introduction ?? "",
                talentID: r.influencer.uuid ?? "",
                liveStatus: 0,
                eventDate: 0,
              }),
              points: r.points,
            },
            
          })
        );
      });
      res.data.weekly_rankings.map((r) => {
        rankings.weekly.push(
          new RankingUserInfo({
            influencer: {
              influencer: new TalentEntity({
                displayName: r.influencer.display_name ?? "",
                genre: r.influencer.genre ?? [],
                images: r.influencer.images?.map((e) => new MainImage({ url: e.url ?? "", position: e.position })) ?? [],
                introduction: r.influencer.introduction ?? "",
                talentID: r.influencer.uuid ?? "",
                liveStatus: 0,
                eventDate: 0,
              }),
              points: r.points,
            },
            
          })
        );
      });
      return rankings;
    });
  }
}
