
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

export type DataType = {
  talentLists: {
    imageSrc: string;
    message: string;
    url: string;
  }[];
};
export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      talentLists: [
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0154_01_%E5%AE%87%E6%9C%88%E3%83%AA%E3%83%AA.JPG",
          message: "宇月リリ",
          url: "https://onlylive.jp/talent/e15d2ffc-fc1f-4c86-a95f-38bd0e7efa29",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0155_02_%E5%85%83%E5%AE%AE%E3%83%A0%E3%82%AD%E3%82%99.JPG",
          message: "元宮ムギ",
          url: "https://onlylive.jp/talent/5419fcdb-2716-442e-b3f5-d8b802ee0025",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0159_04_%E5%90%BE%E5%A6%BB%E3%83%8F%E3%83%AB.jpeg",
          message: "吾妻ハル",
          url: "https://onlylive.jp/talent/80f00ac6-93d1-4492-80a7-0ff90c52b2de",
        },
      ],
    });

    return {
      state
    }
  }
});
