import { defineComponent, h, reactive, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import Talent from "@/entities/talent"

const GetNowTalents = (WrapperComponent: any) =>
  defineComponent({
    name: "GetNowTalentsContainer",
    setup() {
      const state = reactive({
        nowTalents: [] as Talent[],
        nextPageToken: ""
      });

      const repositories = useRepositories()

      async function getNowTalents() {
        try {
          const talents = await repositories.value.TalentRepository.getNowTalents("");
          state.nowTalents = talents[0];
        } catch (e) {
        } finally {
        }
        setTimeout(getNowTalents, 10000);
      }

      onMounted(async () => {
        try {
          await getNowTalents();
        } catch (e) {}
      })

      return {
        state,
      };
    },
    render() {
      return h(
        WrapperComponent, {
          nowTalents: this.state.nowTalents,
        }
      );
    },
  });
export default GetNowTalents;