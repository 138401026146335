import SessionRepository from "@/repositories/local/session_repository";
import ReservationRepository from "@/repositories/Reservation_repository";
import { AxiosInstance } from "axios";

export default class APIReservationRepository implements ReservationRepository {
  client: AxiosInstance;
  sessionRepo: SessionRepository;

  constructor(client: AxiosInstance, sessionRepo: SessionRepository) {
    this.client = client;
    this.sessionRepo = sessionRepo;
  }

  // APIアドレスを直で叩くためbasehostを設定し切り替え
  private basehost = process.env.VUE_APP_BASEHOST;

  async create(fanMeetingId: number): Promise<void> {
    try {
      const auth = await this.sessionRepo.getAuthInfo();

      await this.client.post(
        `https://${this.basehost}/v1/reservations`,
        {
          fan_meeting_id: fanMeetingId,
          fan_uuid: auth.fan_uuid,
        },
        {
          headers: {
            "x-api-jwt": auth.token,
            "x-platform": "web",
          },
        }
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
