import FanRepository from "@/repositories/fan_repository";
import SignInRequest from "@/entities/sign_in";
import { AxiosInstance } from "axios";
import { NotFoundError, UnAuthorizedError } from "@/entities/errors";
import { Errors } from "@/error/error";
import Fan from "@/entities/fan";
import Auth from "@/entities/auth";
import SessionRepository from "@/repositories/local/session_repository";
import { ServerError } from "./error";

interface SignInResponse {
  api_token: string;
  uuid: string;
}

interface GetFanResponse {
  birth: {
    seconds: string;
  };
  annotation_id: string;
  voip_token: string;
  display_name: string;
  image_uri: string;
  uuid: string;
  phone_number: string;
  created_at: {
    seconds: number;
  };
  updated_at: {
    seconds: number;
  };
}

interface GetBalanceResponse {
  balance: number;
}

interface RefreshTokenResponse {
  new_token: string;
}

export default class APIFanRepository implements FanRepository {
  client: AxiosInstance;
  sessionRepo: SessionRepository;

  constructor(client: AxiosInstance, sessionRepo: SessionRepository) {
    this.client = client;
    this.sessionRepo = sessionRepo;
  }

  // APIアドレスを直で叩くためbasehostを設定し切り替え
  private basehost = process.env.VUE_APP_BASEHOST;
  private refreshCount = 0;

  private async refreshToken(): Promise<Auth> {
    try {
      const auth = await this.sessionRepo.getAuthInfo();
      const res = await this.client.post<RefreshTokenResponse>(`https://${this.basehost}/v1/fans/refresh/token`, {
        old_token: auth.token,
      });
      auth.token = res.data.new_token;
      this.sessionRepo.saveAuthInfo(auth);
      return auth;
    } catch (e) {
      throw e;
    }
  }

  async signIn(r: SignInRequest): Promise<Auth> {
    try {
      let phoneNumber = "";
      if (r.phone_number[0] == "1") {
        phoneNumber = `+1${r.phone_number.slice(1)}`;
      } else {
        phoneNumber = `+81${r.phone_number.slice(1)}`;
      }
      const res = await this.client.post<SignInResponse>(`https://${this.basehost}/v1/fans/signin`, {
        phone_number: phoneNumber,
        password: r.password,
      });
      const data = res.data as SignInResponse;
      return new Auth({ fan_uuid: data.uuid, token: data.api_token });
    } catch (e) {
      if (e instanceof NotFoundError) {
        throw new Errors().UserNotFound;
      } else if (e instanceof UnAuthorizedError) {
        throw new Errors().WrongPassword;
      }
      throw e;
    }
  }

  async getFan(): Promise<Fan> {
    try {
      const auth = await this.sessionRepo.getAuthInfo();
      const res = await this.client.get<GetFanResponse>(`https://${this.basehost}/v1/fans/${auth.fan_uuid}`, {
        headers: {
          "x-api-jwt": auth.token,
        },
      });
      const r = res.data;
      return new Fan({
        birth: r.birth.seconds,
        annotation_id: r.annotation_id,
        display_name: r.display_name,
        image_uri: r.image_uri,
        uuid: r.uuid,
        phone_number: r.phone_number,
        created_at: r.created_at.seconds,
        updated_at: r.updated_at.seconds,
      });
    } catch (e) {
      // ２回以上refreshTokenに失敗した場合はerrorをthrowする
      if (e.message == ServerError.TokenExpired && this.refreshCount <= 0) {
        this.refreshCount++;
        await this.refreshToken();
        return await this.getFan();
      }
      throw e;
    }
  }

  async getBalance(): Promise<number> {
    try {
      const auth = await this.sessionRepo.getAuthInfo();
      const res = await this.client.get<GetBalanceResponse>(
        `https://${this.basehost}/v1/wallet/fans/${auth.fan_uuid}`,
        {
          headers: { "x-api-jwt": `${auth.token}` },
        }
      );
      const r = res.data;
      return r.balance;
    } catch (e) {
      // ２回以上refreshTokenに失敗した場合はerrorをthrowする
      if (e.message == ServerError.TokenExpired && this.refreshCount <= 0) {
        this.refreshCount++;
        await this.refreshToken();
        return await this.getBalance();
      }
      throw e;
    }
  }
}
