
import { defineComponent, PropType } from "vue";
import { useRouter } from "vue-router";
import Talent from "@/entities/talent";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

export default defineComponent({
  name: "TalkNowTalentsScreen",
  components: {
    Imgix,
  },
  props: {
    nowTalents: {
      type: Array as PropType<Talent[]>,
      required: true,
    },
  },

  setup() {
    const router = useRouter();
    const imageSize: ImageSize = {
      sm: {
        width: 162,
        height: 216,
      },
      md: {
        width: 160,
        height: 213,
      },
      lg: {
        width: 160,
        height: 213,
      },
    };
    async function openTalent(talentID: string) {
      router.push({ name: "TalentInfoScreen", params: { talent_id: talentID } });
    }

    return {
      openTalent,
      imageSize,
    };
  },
});
