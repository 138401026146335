
import { defineComponent } from "vue";

export default defineComponent({
  name: "PopUp",
  props: {
    text: {
      type: String,
      required: true,
      default: "",
    },
    nextFunction: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
  },
  setup(props) {
    const sleep = async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 3000);
      });
      props.nextFunction();
    };
    sleep();
  },
});
