import { RankingUserInfo } from "@/entities/ranking";
import { Event } from "@/entities/event";
import { useRepositories } from "@/infra/api/injects";

export async function getEventInfoAndRanking(eventParamater: string): Promise<[Event, RankingUserInfo[]]> {
  const repositories = useRepositories();
  try {
    const event = await repositories.value.EventRepository.getEvent(eventParamater);
    const ranking = await repositories.value.EventRepository.getEventRanking(event.id);
    return [event, ranking]
  } catch (e) {
    throw e;
  }
}