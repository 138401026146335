
import { defineComponent, onMounted, onUnmounted, PropType, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Talent from "@/entities/talent";
import Loading from "@/components/atoms/Loading.vue";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";
import { CommunicationState } from "@/type";
export default defineComponent({
  name: "TalkFutureTalents",
  components: {
    Loading,
    Imgix,
  },
  props: {
    futureTalents: {
      type: Array as PropType<Talent[]>,
      required: true,
    },
    communicationState: {
      type: Object as PropType<CommunicationState>,
      required: true,
    },
    isExistNext: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    getFutureTalents() {
      return true;
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    async function openTalent(talentID: string) {
      router.push({ name: "TalentInfoScreen", params: { talent_id: talentID } });
    }

    const scrollComponent = ref<any>(null);
    const isLoading = ref<boolean>(false);

    const imageSize: ImageSize = {
      sm: {
        width: 162,
        height: 216,
      },
      md: {
        width: 160,
        height: 213,
      },
      lg: {
        width: 160,
        height: 213,
      },
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (
        props.isExistNext &&
        props.communicationState === 1 &&
        element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        isLoading.value = true;
        emit("getFutureTalents");
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    watch(
      () => props.communicationState,
      async () => {
        if (props.communicationState === 1) {
          isLoading.value = false;
        }
      }
    );

    return {
      openTalent,
      isLoading,
      scrollComponent,
      imageSize,
    };
  },
});
