
import Ranking from "@/entities/ranking";
import { defineComponent, reactive, ref, watch} from "vue";
import RankingUser from "@/components/common_presentational/RankingUser.vue";
import { RankingUserInfo } from "@/entities/ranking";
import RankingFirst from "@/components/common_presentational/rankings/RankingFirst.vue";
import RankingSecond from "@/components/common_presentational/rankings/RankingSecond.vue";
import RankingThird from "@/components/common_presentational/rankings/RankingThird.vue";
import { CommunicationState } from "@/type";
import Loading from "@/components/atoms/Loading.vue";

  export default defineComponent({
    name: "Ranking",
    components: {
      RankingUser,
      RankingFirst,
      RankingSecond,
      RankingThird,
      Loading
    },
    props: {
      ranking: {
        type: Object as () => Ranking,
        required: true,
      },
      communicationState: {
        type: Object as () => CommunicationState,
        default: 1,
        required: true,
      }
    },
  
    setup(props) { 
       const state = reactive({
        Ranking: {} as Ranking,
        isMonthly: true,
        isWeekly: false,
        weekAgo: "",
        monthAgo: ""
      });
      const rank1Monthly = ref<RankingUserInfo>();
      const rank2Monthly = ref<RankingUserInfo>();
      const rank3Monthly = ref<RankingUserInfo>();
      const rank1Weekly = ref<RankingUserInfo>();
      const rank2Weekly = ref<RankingUserInfo>();
      const rank3Weekly = ref<RankingUserInfo>();
      const lowMonthly = ref<Array<RankingUserInfo>>();
      const lowWeekly = ref<Array<RankingUserInfo>>();
      
      
      watch(
        () => props.ranking,
        () => {    
          rank1Monthly.value = props.ranking.monthly[0];
          rank2Monthly.value = props.ranking.monthly[1];
          rank3Monthly.value = props.ranking.monthly[2];
          lowMonthly.value = props.ranking.monthly.slice(3);
          rank1Weekly.value = props.ranking.weekly[0];
          rank2Weekly.value = props.ranking.weekly[1];
          rank3Weekly.value = props.ranking.weekly[2];
          lowWeekly.value = props.ranking.weekly.slice(3);
        }
      );

      const isLoading = ref<boolean>(false);

      watch(
        () => props.communicationState,
        () => {
          if (props.communicationState == 2) {
            isLoading.value = true;
          } else {
            isLoading.value = false;
          }
        }
      );


      function onChangeMonthly() {
        state.isMonthly = true,
        state.isWeekly = false
      }
      function onChangeWeekly() {
        state.isWeekly = true,
        state.isMonthly = false
      }

      const JANUARY_MONTH = 1;
      const dt = new Date();
      const year = dt.getFullYear();
      const month = dt.getMonth() + 1;
      const day = dt.getDate();
      const today = month === JANUARY_MONTH ? `${year + 1}年${month}月${day}日` : `${month}月${day}日`;

      const now = new Date();
      const oneWeekBefore = new Date();
      const oneMonthBefore = new Date();
      oneWeekBefore.setDate(now.getDate() - 7);
      oneMonthBefore.setMonth(now.getMonth());
      
      function Weekly(date: any, format: any) {
        format = format.replace(/YYYY/, date.getFullYear());
        format = format.replace(/MM/, date.getMonth() + 1);
        format = format.replace(/DD/, date.getDate());
        state.weekAgo = format;
        return state.weekAgo;
      }
      function Monthly(date: any, format: any) {
        format = format.replace(/YYYY/, date.getFullYear());
        format = format.replace(/MM/, date.getMonth());
        format = format.replace(/DD/, date.getDate());
        state.monthAgo = format;
        return state.monthAgo;
      }
      Weekly(oneWeekBefore, 'YYYY年MM月DD日');
      Monthly(oneMonthBefore, 'YYYY年MM月DD日');
        
      return {
        year,
        today,
        state,
        rank1Monthly,
        rank2Monthly,
        rank3Monthly,
        lowMonthly,
        rank1Weekly,
        rank2Weekly,
        rank3Weekly,
        lowWeekly,
        onChangeMonthly,
        onChangeWeekly,
        isLoading,
      }
    }
  });
