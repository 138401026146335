
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseRoundRectButton",
  props: {
    text: {
      type: String,
      required: true,
      default: "Submit",
    },
    isPushed: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
});
