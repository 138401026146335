
import { defineComponent } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";

export default defineComponent({
  name: "ServerErrorScreen",
  components: {
    BaseRoundRectButton,
  },
  setup() {
    return {};
  },
});
