
import { defineComponent, h, reactive, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import OtherTalent from "@/components/presentational/talent/OtherTalent.vue";
import Talent from "@/entities/talent";

export default defineComponent({
  name: "OtherTalentContainer",
  setup() {
    const state = reactive({
      otherTalents: [] as Talent[],
      nextPageToken: ""
    });

    const repositories = useRepositories()

    async function getFutureTalents() {
      const talents = await repositories.value.TalentRepository.getFutureTalents(state.nextPageToken)
        state.nextPageToken = talents[1]
        Array.prototype.push.apply(state.otherTalents, talents[0])
        if(state.nextPageToken != "") {
          getFutureTalents()
        }
    }

    onMounted(async () => {
      await getFutureTalents()
    })

    return {
      state,
    };
  },
  render() {
    return h(OtherTalent, {
      otherTalents: this.state.otherTalents,
    });
  },
});
