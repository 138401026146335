
import { defineComponent, watch, ref } from "vue";
import { useRoute } from "vue-router";
import TalentInfo from "@/components/container/talent/TalentInfoContainer.vue";
import OtherTalent from "@/components/container/talent/OtherTalentContainer.vue";

export default defineComponent({
  name: "TalentInfoScreen",
  components: {
    TalentInfo,
    OtherTalent,
  },
  setup() {
    const route = useRoute()
    const talentID = ref("")

    watch(
      () => route.params,
      async newParams => {
        talentID.value = route.params.talent_id as string
        return newParams
      },
      {
        immediate: true
      },

    )
    return {
      talentID: talentID
    }
  },
});
