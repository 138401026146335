export class FanMeeting {
  constructor(args: FanMeetingArgs) {
    this.id = args.id;
    this.limitedPeople = args.limitedPeople;
    this.state = args.state;
    this.isPreReservation = args.isPreReservation;
    this.eventDate = args.eventDate;
    this.itemCode = args.itemCode;
    this.secondsPerReservation = args.secondsPerReservation;
    this.thumbnailMovieUri = args.thumbnailMovieUri;
    this.style = args.style;
    this.streamerLiveStreamMethodType = args.streamerLiveStreamMethodType;
    this.viewerLiveStreamMethodType = args.viewerLiveStreamMethodType;
    this.campaignUuid = args.campaignUuid;
    this.liveStreamImageUri = args.liveStreamImageUri;
    this.chekiPrice = args.chekiPrice;
    this.isExtension = args.isExtension;
    this.title = args.title;
    this.themeId = args.themeId;
  }
  id: number;
  limitedPeople: number;
  state: FanMeetingState;
  isPreReservation: boolean;
  eventDate: Date;
  itemCode: string;
  secondsPerReservation: number;
  thumbnailMovieUri: string;
  isExtension: boolean;
  style: FanMeetingStyle;
  streamerLiveStreamMethodType: LiveStreamMethodType;
  viewerLiveStreamMethodType: LiveStreamMethodType;
  campaignUuid?: string;
  liveStreamImageUri: string;
  chekiPrice: number;
  title: string;
  themeId: number;

  get convertUnixToDate() {
    const MM = ("0" + (this.eventDate.getMonth() + 1)).slice(-2);
    const dd = ("0" + this.eventDate.getDate()).slice(-2);
    const hh = ("0" + this.eventDate.getHours()).slice(-2);
    const mm = ("0" + this.eventDate.getMinutes()).slice(-2);
    return MM + "/" + dd + " " + hh + ":" + mm + "-";
  }
}

export type FanMeetingArgs = {
  id: number;
  limitedPeople: number;
  state: FanMeetingState;
  isPreReservation: boolean;
  eventDate: Date;
  itemCode: string;
  secondsPerReservation: number;
  thumbnailMovieUri: string;
  isExtension: boolean;
  style: FanMeetingStyle;
  streamerLiveStreamMethodType: LiveStreamMethodType;
  viewerLiveStreamMethodType: LiveStreamMethodType;
  campaignUuid?: string;
  liveStreamImageUri: string;
  chekiPrice: number;
  title: string;
  themeId: number;
};

export const FanMeetingState = {
  unspecified: 0,
  finish: 1,
  now: 2,
  future: 3,
  cancel: 4,
  notHeld: 5,
} as const;

export type FanMeetingState = typeof FanMeetingState[keyof typeof FanMeetingState];

export const FanMeetingStyle = {
  unspecified: 0,
  regular: 1,
  serial: 2,
} as const;

export type FanMeetingStyle = typeof FanMeetingStyle[keyof typeof FanMeetingStyle];

export const LiveStreamMethodType = {
  unspecified: 0,
  video: 1,
  image: 2,
} as const;

export type LiveStreamMethodType = typeof LiveStreamMethodType[keyof typeof LiveStreamMethodType];
