
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "UsecaseItem",
  props: {
    image: {
      type: String as PropType<string>,
      required: true,
    },
    headline: {
      type: String as PropType<string>,
      required: true,
    },
    body: {
      type: String as PropType<string>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    subText: {
      type: String,
      required: false,
    },
    img: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
