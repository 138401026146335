import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "TalentInfoScreen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TalentInfo = _resolveComponent("TalentInfo")
  const _component_OtherTalent = _resolveComponent("OtherTalent")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_TalentInfo, { key: _ctx.talentID }),
    _createVNode(_component_OtherTalent)
  ]))
}