
import { defineComponent, PropType } from "vue";
import Ranking from "@/components/presentational/event/Ranking.vue";
import { Event } from "@/entities/event";
import { CommunicationState } from "@/type";
import { RankingUserInfo } from "@/entities/ranking";

export default defineComponent({
  name: "EventRankingScreen",
  components: {
    Ranking,
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    rankingUsers: {
      type: Array as PropType<RankingUserInfo[]>,
      required: true,
    },
    communicationState: {
      type: Object as PropType<CommunicationState>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
