import { defineComponent, h, reactive, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import Talent from "@/entities/talent"

const GetAllTalents = (WrapperComponent: any) =>
  defineComponent({
    name: "GetAllTalentsContainer",
    setup() {
      const state = reactive({
        allTalents: [] as Talent[],
        nextPageToken: ""
      });

      const repositories = useRepositories()

      async function getAllTalents() {
        const talents = await repositories.value.TalentRepository.getAllTalents(state.nextPageToken)
        state.nextPageToken = talents[1]
        Array.prototype.push.apply(state.allTalents, talents[0])
        if(state.nextPageToken != "") {
          getAllTalents()
        }
      }

      onMounted(async () => {
        await getAllTalents()
      })

      return {
        state,
      };
    },
    render() {
      return h(
        WrapperComponent, {
          allTalents: this.state.allTalents,
        }
      );
    },
  });
export default GetAllTalents;