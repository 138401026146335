import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueElementLoading = _resolveComponent("VueElementLoading")

  return (_openBlock(), _createBlock(_component_VueElementLoading, {
    active: _ctx.isActive,
    "is-full-screen": _ctx.isFullScreen,
    color: _ctx.property.color,
    "background-color": _ctx.property.backgroundColor
  }, null, 8, ["active", "is-full-screen", "color", "background-color"]))
}