import { defineComponent, h, reactive, onMounted, ref } from "vue";
import { useRepositories } from "@/infra/api/injects";
import Talent from "@/entities/talent";
import { CommunicationState } from "@/type";
import { faIgloo } from "@fortawesome/free-solid-svg-icons";

const GetFutureTalents = (WrapperComponent: any) =>
  defineComponent({
    name: "GetFutureTalentsContainer",
    setup() {
      const state = reactive({
        futureTalents: [] as Talent[],
        nextPageToken: ""
      });

      const repositories = useRepositories();
      const communicationState = ref<CommunicationState>(1);
      const isExistNext = ref<boolean>(true);

      async function getFutureTalents() {
        try {
          communicationState.value = 2;
          const talents = await repositories.value.TalentRepository.getFutureTalents(state.nextPageToken);
          state.nextPageToken = talents[1]
          Array.prototype.push.apply(state.futureTalents, talents[0])
          if (state.nextPageToken !== "") {
            await getFutureTalents()
          }
          if(talents[0].length === 0) isExistNext.value = false;
        } catch (e) {
          isExistNext.value = false;
        } finally {
          communicationState.value = 1;
        }
        setTimeout(getFutureTalents, 60000);
      }

      onMounted(async () => {
        try {
          await getFutureTalents();
        } catch (e) {}
      });

      return {
        state,
        getFutureTalents,
        communicationState,
        isExistNext,
      };
    },
    render() {
      return h(WrapperComponent, {
        futureTalents: this.state.futureTalents,
        communicationState: this.communicationState,
        onGetNextFutureTalents: () => this.getFutureTalents(),
        isExistNext: this.isExistNext,
      });
    },
  });
export default GetFutureTalents;
