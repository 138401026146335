
import { defineComponent } from "vue";
import LPRanking from "@/components/presentational/event/Ranking.vue";
export default defineComponent({
  name: "ModiScreen",
  components: {
    LPRanking,
  },
  setup() {
    const Tops = [
      "①onlyliveの広告タレントとして\n「渋谷モディビジョン広告」に掲載",
      "②渋谷モディ7F・onlyliveイベントブースにて\n　等身大パネルの掲出",
      "③渋谷モディ7F・onlyliveイベントブースにて\n 15秒〜30秒の告知動画の掲載が可能",
    ];

    const SecThirds = [
      "①onlyliveの広告タレントとして\n「渋谷モディビジョン広告」に掲載",
      "②渋谷モディ7F・onlyliveイベントブースにて\n等身大パネルの掲出",
    ];

    return {
      Tops,
      SecThirds,
    };
  },
});
