
  import { defineComponent, onMounted, reactive } from "vue";
  import Ranking from "@/components/presentational/event/EventRankingWithFirebase.vue";
  import { RankingUserInfo } from "@/entities/ranking";
  import { Event } from "@/entities/event";
  import { getEventInfoAndRanking } from "@/util/get_event";
  
  export default defineComponent({
    name: "Winter2212Screen",
    components: {
      Ranking,
    },
    setup() {
        const state = reactive({
          event: {} as Event,
          ranking: [] as Array<RankingUserInfo>,
          communicationState: 1,
        });
  
        onMounted(async () => {
          const response = await getEventInfoAndRanking("2212_winter");
          state.event = response[0];
          state.ranking = response[1];
        });
        return {
          state,
        };
      }
  });
  