
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

export type DataType = {
  talentLists: {
    imageSrc: string;
    message: string;
    url: string;
  }[];
};
export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      talentLists: [
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0195_0_%E4%BD%90%E8%97%A4%E5%92%8C%E6%B3%89.jpeg",
          message: "佐藤和泉",
          url: "https://onlylive.jp/talent/8fdeec23-6359-42de-9744-9130a65adbad",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0196_0_%E6%9F%9A%E6%9C%A8.jpeg",
          message: "柚木那夏",
          url: "https://onlylive.jp/talent/6197f1bf-210c-4463-b611-9d6f6d4eb755",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0197_3_%E6%98%9F%E5%AE%AE%E3%81%93%E3%81%AE%E3%81%8B.jpeg",
          message: "星宮このか",
          url: "https://onlylive.jp/talent/c2779119-ccd7-4eef-a0b3-468eebe46003",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0198_1_%E5%A7%AB%E4%B9%83%E3%82%82%E3%81%A8%E3%81%BF.jpeg",
          message: "姫乃もとみ",
          url: "https://onlylive.jp/talent/c99aedb8-d475-4bf5-a41a-cf3a5a70e6b0",
        },
      ],
    });

    return {
      state
    }
  }
});
