import { inject, InjectionKey } from 'vue'
import { Repositories } from "@/@types/repositories"

// injectで取り出す値の型をジェネリクスとしてRepositoriesに渡す
export const repo: InjectionKey<Repositories> = Symbol('repo')

// 使いまわせるように関数化しておく
export function useRepositories() {
  // 先ほど定義したRepositoriesを読み込んでinjectで取り出す
  const repositories = inject(repo)
  if (!repositories) {
    throw new Error('useRepositories() is called without provider.')
  }
  return repositories
}