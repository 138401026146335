
import { defineComponent, onMounted, reactive } from "vue";
import LPRankingUser from "@/components/common_presentational/LPRankingUser.vue";
import RankingFirst from "@/components/common_presentational/rankings/RankingFirst.vue";
import RankingSecond from "@/components/common_presentational/rankings/RankingSecond.vue";
import RankingThird from "@/components/common_presentational/rankings/RankingThird.vue";
import { activate, fetchConfig, getRemoteConfig, getValue } from "firebase/remote-config";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { RankingUserInfo } from "@/entities/ranking";
import { useRepositories } from "@/infra/api/injects";
import Talent from "@/entities/talent";

export default defineComponent({
  name: "LPRanking",
  components: {
    LPRankingUser,
    RankingFirst,
    RankingSecond,
    RankingThird,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      weekAgo: "",
      monthAgo: "",
      isWeekly: false,
      isMonthly: true,
      monthly: [] as Array<RankingUserInfo>,
      isLoading: true,
      enableEvent: false,
    });

    const loadImg: any = () => {
      return require(`../../../../assets/img/event/${props.img}`);
    };

    const repo = useRepositories();
    onMounted(async () => {
      // TODO: Replace the following with your app's Firebase project configuration
      const firebaseConfig = {
        projectId: process.env.VUE_APP_FIRE_BASE_PROJECT_ID,
        apiKey: process.env.VUE_APP_FIRE_BASE_API_KEY,
        appId: process.env.VUE_APP_FIRE_BASE_APP_ID,
      } as FirebaseOptions;

      const app = initializeApp(firebaseConfig);
      const remoteConfig = getRemoteConfig(app);
      remoteConfig.defaultConfig = {
        welcome_message: "Welcome",
      };
      remoteConfig.settings.minimumFetchIntervalMillis = 0;
      await fetchConfig(remoteConfig);
      await activate(remoteConfig);

      const val = getValue(remoteConfig, "ueno_ranking").asString();
      state.enableEvent = getValue(remoteConfig, "enable_ueno_event").asBoolean();
      if (!state.enableEvent) {
        for (let i = 0; i < 10; i++) {
          state.monthly.push(
            new RankingUserInfo({
                influencer: {
                  influencer: new Talent({
                    displayName: "",
                    eventDate: 0,
                    genre: [],
                    images: [],
                    introduction: "",
                    talentID: "",
                    liveStatus: 0,
                  }),
                  points: 0,
                }, 
                
              })
            );
        }
        state.isLoading = false;
        return;
      }
      const json = JSON.parse(val);
      for (let i = 0; i < 10; i++) {
        const element = json["ranking"];
        if (element.length <= i) {
          state.monthly.push(
            new RankingUserInfo({
                influencer: {
                  influencer: new Talent({
                    displayName: "",
                    eventDate: 0,
                    genre: [],
                    images: [],
                    introduction: "",
                    talentID: "",
                    liveStatus: 0,
                  }),
                  points: 0,
                }, 
              })
            );
        } else {
          await repo.value.TalentRepository.getTalentByID(element[i]["uuid"] as string).then((talent) => {
            state.monthly.push(
              new RankingUserInfo({
                influencer: {
                  influencer: talent,
                  points: element[i]["point"],
                },
                
              })
            );
          });
        }
      }
      state.isLoading = false;
    });

    const JANUARY_MONTH = 1;
    const now = new Date();
    let endDate = "";

    if (new Date(2021, 11, 31) < now) {
      endDate = "12月31日";
    } else {
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      endDate = month === JANUARY_MONTH ? `${year + 1}年${month}月${day}日` : `${month}月${day}日`;
    }

    const oneWeekBefore = new Date();
    const oneMonthBefore = new Date();
    oneWeekBefore.setDate(now.getDate() - 7);
    oneMonthBefore.setMonth(now.getMonth());

    function Monthly(date: any, format: any) {
      format = format.replace(/YYYY/, date.getFullYear());
      format = format.replace(/MM/, date.getMonth());
      format = format.replace(/DD/, date.getDate());
      state.monthAgo = format;
      return state.monthAgo;
    }
    Monthly(oneMonthBefore, "YYYY年MM月DD日");
    return {
      endDate,
      state,
      loadImg,
    };
  },
});
