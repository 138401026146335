import { FanMeeting } from "@/entities/fan_meeting";

export class FanMeetingAndReserved {
  constructor(args: FanMeetingAndReservedgArgs) {
    this.reservationSummaryState = args.reservationSummaryState;
    this.fanMeeting = args.fanMeeting;
    this.isReserved = args.isReserved;
  }
  fanMeeting: FanMeeting;
  reservationSummaryState: ReservationSummaryState;
  isReserved: boolean;
}

export class ReservationSummaryState {
  constructor(args: ReservationSummaryStateArgs) {
    this.waitNum = args.waitNum;
    this.waitSeconds = args.waitSeconds;
    this.isSoldOut = args.isSoldOut;
    this.remainingNum = args.remainingNum;
  }
  waitNum: number;
  waitSeconds: number;
  isSoldOut: boolean;
  remainingNum: number;
}

export type ReservationSummaryStateArgs = {
  waitNum: number;
  waitSeconds: number;
  isSoldOut: boolean;
  remainingNum: number;
};

export type FanMeetingAndReservedgArgs = {
  fanMeeting: FanMeeting;
  reservationSummaryState: ReservationSummaryState;
  isReserved: boolean;
};
