import Talent from "@/entities/talent";
import { useRepositories } from "@/infra/api/injects";

export interface OfficeTalentId {
  talentID: string;
}

export async function getOfficeTalents(officeTalentIds: OfficeTalentId[]) {
  const repositories = useRepositories();
  const officeTalents: Talent[] = [];
  for (let i = 0; i < officeTalentIds.length; i++) {
    const t = await repositories.value.TalentRepository.getTalentByID(officeTalentIds[i].talentID);
    officeTalents.push(
        new Talent({
          displayName:t.displayName,
          eventDate: t.eventDate,
          genre:t.genre,
          images: t.images,
          introduction:t.introduction,
          talentID:t.talentID,
          liveStatus: t.liveStatus,
        })
      );
  }
  return officeTalents;
}