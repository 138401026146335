
import { defineComponent, onMounted, reactive } from "vue";
import Ranking from "@/components/presentational/event/EventRankingWithFirebase.vue";
import { RankingUserInfo } from "@/entities/ranking";
import { Event } from "@/entities/event";
import { getEventInfoAndRanking } from "@/util/get_event";

export default defineComponent({
  name: "UenoScreen",
  components: {
    Ranking,
  },
  setup() {
    const CastLists = [
      "稲田直樹（アインシュタイン）",
      "水野勝（BOYS AND MEN）",
      "熊田曜子",
      "なんしぃ",
      "暁月ななみ",
      "濱津隆之",
      "ダイアモンド✡️ユカイ",
      "木下ほうか",
      "緑川静香",
      "ざわちん",
      "鹿目凛（でんぱ組.inc）",
      "神田うの ※特別出演",
      "他",
    ];
      const state = reactive({
        event: {} as Event,
        ranking: [] as Array<RankingUserInfo>,
        communicationState: 1,
      });

      onMounted(async () => {
        const response = await getEventInfoAndRanking("2201_kaiki");
        state.event = response[0];
        state.ranking = response[1];
      });
      return {
        state,
        CastLists,
      };
    }
});
