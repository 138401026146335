
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";

export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
    BaseRoundRectButton
  },
  setup() {
    const state = reactive({
      imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/milenia/LA0160_himawari.jpg",
      message: "ひまわり",
      url: "https://onlylive.jp/talent/8a8ae7eb-aab9-4673-86c4-6848bf4e7997",
      isHover: false,
    });

    return {
      state
    }
  }
});
