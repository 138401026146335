
import { defineComponent } from "vue";

export interface ImageSize {
  sm: {
    width: number;
    height: number;
  };
  md: {
    width: number;
    height: number;
  };
  lg: {
    width: number;
    height: number;
  };
}

export default defineComponent({
  name: "Imgix",
  props: {
    imageUrl: {
      type: String,
      required: true,
      default: "",
    },
    imageSize: {
      type: Object as () => ImageSize,
      required: true,
    }
  },

  setup(props) {
    function addQueryParams(source: string, queryParams: { [key: string]: string }): string {
      const addSource = [] as string[];
      for (const key in queryParams) {
        addSource.push(`${key}=${queryParams[key]}`);
      }
      return `${source}?${addSource.join("&")}`;
    }

    const getImageUrl = (imageUrl: string) => {
      const devicePixelRatio = window.devicePixelRatio;
      const regExp = new RegExp(process.env.VUE_APP_GCS_SOURCE_PATH);
      const imgixUrl = imageUrl.replace(regExp, process.env.VUE_APP_IMGIX_HOST);
      const w = window.innerWidth;
      const queryParams: { [key: string]: string } = {
        q: "100",
        dpr: devicePixelRatio.toString(),
        fit: "crop",
      };
      if (w > 1023) {
        queryParams["w"] = props.imageSize.lg.width.toString();
        queryParams["h"] = props.imageSize.lg.height.toString();
      } else if (w > 767) {
        queryParams["w"] = props.imageSize.md.width.toString();
        queryParams["h"] = props.imageSize.md.height.toString();
      } else {
        queryParams["w"] = props.imageSize.sm.width.toString();
        queryParams["h"] = props.imageSize.sm.height.toString();
      }
      return addQueryParams(imgixUrl, queryParams);
    };

    return {
      getImageUrl,
    };
  },
});
