import Talent from "./talent";

export type RankingArgs = {
  weekly: RankingUserInfo[];
  monthly: RankingUserInfo[];
};

export default class Ranking {
  weekly: RankingUserInfo[];
  monthly: RankingUserInfo[];

  constructor(r: RankingArgs) {
    this.weekly = r.weekly;
    this.monthly = r.monthly;
  }
}

export type RankingUserInfoArgs = {
  influencer: {
    influencer: Talent;
    points: number;
  };
};

export class RankingUserInfo {
  influencer: {
    influencer: Talent;
    points: number;
  };
  constructor(r: RankingUserInfoArgs) {
    this.influencer = r.influencer;
  }
}
