
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

export type DataType = {
  talentLists: {
    imageSrc: string;
    message: string;
    url: string;
  }[];
};
export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      talentLists: [
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0113_%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%80%80%E3%83%92%E3%83%AD.jpeg",
          message: "ヒロ",
          url: "https://onlylive.jp/talent/770385e7-c760-4643-ac72-3d50a5b4924b",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0114_%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%80%80%E3%82%A2%E3%83%84%E3%82%AD.jpeg",
          message: "アツキ",
          url: "https://onlylive.jp/talent/7ee3c0fc-d558-4a68-954b-12f17355739a",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0115_%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%80%80%E3%83%AC%E3%82%AA%E3%83%8A.jpeg",
          message: "レオナ",
          url: "https://onlylive.jp/talent/3163693b-c718-4023-a7aa-90cc5fc6ae97",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0116__%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%80%80%E3%82%B3%E3%83%BC%E3%82%BF.jpeg",
          message: "コータ",
          url: "https://onlylive.jp/talent/fdad0ebc-3c8e-41d8-bebd-f3dcb95e8227",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0117_%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%83%8F%E3%82%99%E3%83%92%E3%82%99%E3%80%80%E3%83%AA%E3%83%A5%E3%83%BC%E3%83%8F%E3%82%99.jpeg",
          message: "リューバ",
          url: "https://onlylive.jp/talent/ccd83b91-9335-4e95-9da9-5232268f058d",
        },
      ],
    });

    return {
      state
    }
  }
});
