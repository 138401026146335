import Auth from "@/entities/auth";
import SessionDraftRepository from "@/repositories/local/session_repository";
import { getDecryptedString, getEncryptedString } from "@/util/encrypt";

export default class LocalSessionRepository implements SessionDraftRepository {
  getAuthInfo(): Promise<Auth> {
    const authInfoJson = localStorage.getItem("session");
    if (authInfoJson) {
      const authInfo = JSON.parse(authInfoJson);
      if (authInfo) {
        authInfo.fan_uuid = getDecryptedString(authInfo.fan_uuid);
        return Promise.resolve(authInfo);
      }
    }
    return Promise.reject("処理中にエラーが発生しました。");
  }

  saveAuthInfo(auth: Auth): Promise<void> {
    auth.fan_uuid = getEncryptedString(auth.fan_uuid);
    localStorage.setItem("session", JSON.stringify(auth));
    return Promise.resolve();
  }

  removeAuthInfo() {
    localStorage.removeItem("session");
    return Promise.resolve();
  }
}
