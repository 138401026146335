
import { defineComponent, reactive, PropType, ref, watch } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import { Stripe, StripeCardNumberElement, StripeError } from "@stripe/stripe-js";
import { Product } from "@/entities/product";
import Loading from "@/components/atoms/Loading.vue";
import PopUp from "@/components/atoms/PopUp.vue";
import { CommunicationState, ResultState } from "@/type";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "PaymentScreen",
  components: {
    BaseRoundRectButton,
    Loading,
    PopUp,
  },
  props: {
    stripe: {
      type: Object as PropType<Stripe | null>,
      require: true,
      default: null,
    },
    product: {
      type: Object as PropType<Product>,
      require: true,
      default: { code: "", amount: 0, point: 0 },
    },
    communicationState: {
      type: Object as () => CommunicationState,
      default: 1,
      required: true,
    },
    resultState: {
      type: Object as () => ResultState,
      default: 0,
      required: true,
    },
    validationError: {
      type: Object as () => StripeError,
      required: false,
      default: null,
    },
  },
  emits: {
    purchase(element: StripeCardNumberElement) {
      if (element != null) return true;
    },
  },
  setup(props, { emit }) {
    const isLoading = ref<boolean>(true);
    const cardNumberError = ref<boolean>(true);
    const cardExpiryError = ref<boolean>(true);
    const cardCvcError = ref<boolean>(true);
    const state = reactive({
      cardNumberErrorMessage: undefined as string | undefined,
      cardCvcErrorMessage: undefined as string | undefined,
      cardExpiryErrorMessage: undefined as string | undefined,
    });
    let cardNumberElement: StripeCardNumberElement;
    const style = {
      base: {
        backgroundColor: "#F6FAFF",
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        lineHeight: "2em",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    const cardError = (): boolean => {
      return cardNumberError.value || cardCvcError.value || cardExpiryError.value;
    };
    const initStripe = () => {
      if (props.stripe == null) return;
      const elements = props.stripe.elements();
      const cardCvc = elements.create("cardCvc", { style: style });
      const cardExpiry = elements.create("cardExpiry", { style: style });
      cardNumberElement = elements.create("cardNumber", { style: style });
      cardCvc.mount("#card-cvc");
      cardNumberElement.mount("#card-number");
      cardExpiry.mount("#card-expiry");
      cardNumberElement.on("change", function (event) {
        if (event.error) {
          state.cardNumberErrorMessage = event.error.message;
          cardNumberError.value = true;
        } else {
          cardNumberError.value = false;
        }
      });
      cardCvc.on("change", function (event) {
        if (event.error) {
          state.cardCvcErrorMessage = event.error.message;
          cardCvcError.value = true;
        } else {
          cardCvcError.value = false;
        }
      });

      cardExpiry.on("change", function (event) {
        if (event.error) {
          state.cardExpiryErrorMessage = event.error.message;
          cardExpiryError.value = true;
        } else {
          cardExpiryError.value = false;
        }
      });
    };
    const purchase = () => {
      if (!cardError() || props.communicationState !== 2) {
        emit("purchase", cardNumberElement);
      }
    };
    watch(
      () => props.communicationState,
      () => {
        if (props.communicationState === 2) {
          isLoading.value = true;
        } else {
          initStripe();
          setTimeout(() => (isLoading.value = false), 300);
        }
      }
    );
    const router = useRouter();
    const toTopScreen = () => {
      router.push({ name: "TopScreen" });
    };
    const purchaseResultMessage = ref<string>("エラーが発生しました。時間をおいて再度お試しください");
    //購入APIが終了後間ポップアップを表示する
    watch(
      () => props.resultState,
      async () => {
        if (props.resultState === 1) {
          purchaseResultMessage.value = "購入が完了しました";
        } else if (props.resultState === 2) {
          purchaseResultMessage.value = "エラーが発生しました。時間をおいて再度お試しください";
        }
      }
    );
    watch(
      () => props.validationError,
      async () => {
        if ((props.validationError.code as string) == "incomplete_number") {
          state.cardNumberErrorMessage = props.validationError.message;
          cardNumberError.value = true;
        }
        if ((props.validationError.code as string) == "incomplete_expiry") {
          state.cardExpiryErrorMessage = props.validationError.message;
          cardExpiryError.value = true;
        }
        if ((props.validationError.code as string) == "incomplete_cvc") {
          state.cardCvcErrorMessage = props.validationError.message;
          cardCvcError.value = true;
        }
      }
    );
    return {
      toTopScreen,
      state,
      cardNumberError,
      cardCvcError,
      cardExpiryError,
      purchase,
      isLoading,
      cardError,
      purchaseResultMessage,
    };
  },
});
