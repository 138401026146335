
  import { defineComponent, reactive } from "vue";
  import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

  export type DataType = {
    talentLists: {
      imageSrc: string;
      message: string;
      url: string;
    }[];
  };

  export default defineComponent({
    name: "Log1nTalentList",
    components: {
      TalentListCard
    },
    setup() {
      const state = reactive({
        talentLists: [
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0187_NiA2.jpeg",
            message: "NiA",
            url: "https://onlylive.jp/talent/7f7205b3-32ab-4920-93a1-caeb986a2a2b",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0186_%E5%9B%BD%E6%9C%A8%E7%94%B0%E3%81%95%E3%82%8A%E3%81%BE%E3%82%8B2.jpeg",
            message: "国木田さりまる",
            url: "https://onlylive.jp/talent/2fe86fd0-5b5e-489e-b3ca-accfb44316f1",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0188_%E3%81%AA%E3%81%A1%E3%81%93.jpeg",
            message: "なちこ",
            url: "https://onlylive.jp/talent/cc23b341-79dc-4a2b-b6b5-86569c723a93",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0185_%EF%BE%8A%EF%BE%9E%EF%BE%8C%EF%BE%9E%EF%BE%90%E3%83%BB%EF%BE%93%EF%BD%B43.jpeg",
            message: "ﾊﾞﾌﾞﾐ・ﾓｴ",
            url: "https://onlylive.jp/talent/87454cca-8e55-424f-89a5-e48c7fb0a7cf",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0189_%E3%82%8A%E3%81%83%E3%81%82%E3%82%93.jpeg",
            message: "りぃあん",
            url: "https://onlylive.jp/talent/c2e16f15-6580-444f-9e06-543d108711fc",
          },
        ]
      })
      return {
        state,
      }
    }
  })
