export class BaseError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, BaseError.prototype);
  }
}

export class ValidationError extends BaseError {
  constructor(m: string) {
    super(m);
    // Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class NotFoundError extends BaseError {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class UnAuthorizedError extends BaseError {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, UnAuthorizedError.prototype);
  }
}

export class ConflictError extends BaseError {
  constructor(m: string) {
    super(m);
    // Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

export class InternalError extends BaseError {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, InternalError.prototype);
  }
}

export class UnknownError extends BaseError {
  constructor(m: string) {
    super(m);
    // Object.setPrototypeOf(this, UnknownError.prototype);
  }
}
