export const CommunicationState = {
  noting: 1, //　未通信
  doing: 2, //　通信中
} as const;

export type CommunicationState = typeof CommunicationState[keyof typeof CommunicationState];

const ResultState = {
  unknown: 0, // 不明
  success: 1, //　成功
  failure: 2, //　失敗
} as const;

export type ResultState = typeof ResultState[keyof typeof ResultState];
