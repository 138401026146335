
import { defineComponent, onMounted, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/OfficeTalentListCard.vue";
import { ImageSize } from "@/components/atoms/Imgix.vue";
import Talent from "@/entities/talent";
import { getOfficeTalents } from "@/util/get_office_talents";

export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      officeTalents: [] as Talent[],
      officeTalentIds: [
        {
          talentID: "8d8ba558-1bfc-4067-9dcf-a7c83f12c382",
        },
         {
          talentID: "39b593a2-2050-4efa-a14e-39a7fff17d64",
        },
        {
          talentID: "efc6e7c3-d8ab-4dcd-ad55-51a35fb6cc8f",
        },
        {
          talentID: "9993c67d-1ed4-49a1-99dc-cb6921f1eb16",
        },
        {
          talentID: "8220a58e-acc2-4a49-b1e1-7555228fd937",
        },
         {
          talentID: "7aafde18-cc80-4b76-929f-6a6be3e86214",
        },
        {
          talentID: "e114a246-f7b5-4aa7-93c7-e7f4ca0ed41b",
        },
        {
          talentID: "126eac9a-1147-4328-b9ac-304acf4d189f",
        },
        {
          talentID: "0fe11f9e-628c-4acf-b683-63bf8c1a1901",
        },
         {
          talentID: "4e2cf1fc-00ae-4bbd-8289-578ec9b6af26",
        },
        {
          talentID: "d50173c5-25a6-4ff9-aae3-878002c46941",
        }
      ],
    });

    onMounted(async () => {
      try {
        state.officeTalents = await getOfficeTalents(state.officeTalentIds);
      } catch (e) {}
    });

    const imageSize: ImageSize = {
      sm: {
        width: 125,
        height: 175,
      },
      md: {
        width: 150,
        height: 250,
      },
      lg: {
        width: 200,
        height: 300,
      },
    }; 

    return {
      state,
      imageSize
    }
  }
});
