export class LocalizationError extends Error {
  super(message: string) {
    this.message = message;
  }
}

export class Errors {
  public UserNotFound = new LocalizationError("アカウントが存在しません");
  public WrongPassword = new LocalizationError("電話番号もしくはパスワードが違います");
  public SystemError = new LocalizationError("ネットワーク環境などをご確認の上、もう一度お試しください");
}
