import axios from "axios";
import { NotFoundError, ConflictError, UnknownError, UnAuthorizedError, InternalError } from "@/entities/errors";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        throw new UnAuthorizedError(error.response.data["message"]);
      case 404:
        throw new NotFoundError(error.response.data["message"]);
      case 409:
        throw new ConflictError(error.response.data["message"]);
      case 500:
        throw new InternalError(error.response.data["message"]);
      default:
        throw new UnknownError(error.response?.data["message"] || "サーバーとの通信中に問題が発生しました。");
    }
  }
);

export default axios;
