
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

export type DataType = {
  talentLists: {
    imageSrc: string;
    message: string;
    url: string;
  }[];
};
export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      talentLists: [
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/k-point/modokashi-markets/LA0109_amemiya_sakura.jpg",
          message: "雨宮桜",
          url: "https://onlylive.jp/talent/344cd534-3283-412f-8c5b-92447b3398ba",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/k-point/modokashi-markets/LA0110_shinohara_aki.jpg",
          message: "篠原亜希",
          url: "https://onlylive.jp/talent/505eab19-24e4-44eb-9270-dceba05ca4aa",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/k-point/modokashi-markets/LA0111_nanase_ayana.jpg",
          message: "七瀬彩耶",
          url: "https://onlylive.jp/talent/f09bc043-5245-430d-8c7e-d69f52be364f",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/k-point/modokashi-markets/LA0112_matsuoka_mei.jpg",
          message: "松岡夢依",
          url: "https://onlylive.jp/talent/ac5d7fae-a17a-47b5-aab2-78c9e36d9963",
        },
      ],
    });

    return {
      state
    }
  }
});
