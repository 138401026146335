
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "SearchComponent",
  setup() {
    const state = reactive({
      items: [
        {
          text: "男性タレント",
        },
        {
          text: "女性タレント",
        }
      ],
    })
    return {
      state
    };
  }
});
