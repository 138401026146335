export type EventArgs = {
  id: number;
  caution?: string;
  startDate: Date;
  endDate: Date;
};

export class Event {
  id: number;
  startDate: Date;
  endDate: Date;

  constructor(f: EventArgs) {
    this.id = f.id;
    this.startDate = f.startDate;
    this.endDate = f.endDate;
  }
}
