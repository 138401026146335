
import { defineComponent } from "vue";
import VueElementLoading from "vue-element-loading";
export default defineComponent({
  name: "Loading",
  components: {
    VueElementLoading,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    let property: { color: string; backgroundColor: string };
    if (props.isFullScreen) {
      property = {
        color: "#96D1E8",
        backgroundColor: "rgba(255, 255, 255, .6)	",
      };
    } else {
      property = {
        color: "#96D1E8",
        backgroundColor: "none",
      };
    }
    return {
      property,
    };
  },
});
