
import { defineComponent } from "vue";
import Header from "@/components/common_presentational/Header.vue";
import Footer from "@/components/common_presentational/TheFooter.vue";
export default defineComponent({
  components: {
    Header,
    Footer,
  },
  created() {
    //Detect window risize
    this.setViewport();
    window.addEventListener("resize", this.setViewport);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setViewport);
  },
  methods: {
    setViewport(): void {
      const viewport = document.querySelector('meta[name="viewport"]')!;
      const value = window.outerWidth > 360 ? "width=device-width,initial-scale=1" : "width=360";
      if (viewport.getAttribute("content") !== value) {
        viewport.setAttribute("content", value);
      }
      return;
    },
  },

});
