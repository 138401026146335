
import { defineComponent } from "vue";
import UsecaseItem from "./UsecaseItem.vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";

export default defineComponent({
  name: "Usecase",
  components: {
    UsecaseItem,
    BaseRoundRectButton,
  },
});
