import { defineComponent, h, onMounted, ref } from "vue";
import { useRepositories } from "@/infra/api/injects";
import { LocalizationError } from "@/error/error";
import { CommunicationState } from "@/type";
import { SignInInfoArgs } from "@/entities/sign_in";
import { useRouter } from "vue-router";
const SignIn = (WrappedComponent: any) =>
  defineComponent({
    name: "SignInContainer",
    setup() {
      const error = ref<LocalizationError | null>(null);
      const communicationState = ref<CommunicationState>(1);
      const repositories = useRepositories();
      const router = useRouter();
      onMounted(async () => {
        try {
          await repositories.value.SessionRepository.getAuthInfo();
          router.push({ name: "TopScreen" });
        } catch (e) {}
      });

      async function signIn(req: SignInInfoArgs): Promise<void> {
        try {
          communicationState.value = 2;
          const auth = await repositories.value.FanRepository.signIn(req);
          await repositories.value.SessionRepository.saveAuthInfo(auth);

          // リロードしてheaderの情報を更新する
          location.reload();
        } catch (e) {
          if (e instanceof LocalizationError) {
            error.value = e;
          }
        } finally {
          communicationState.value = 1;
        }
      }
      return {
        signIn,
        error,
        communicationState,
      };
    },
    render() {
      return h(WrappedComponent, {
        communicationState: this.communicationState,
        localizationError: this.error,
        onSignIn: (request: SignInInfoArgs) => this.signIn(request),
      });
    },
  });
export default SignIn;
