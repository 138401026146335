
import { defineComponent } from "vue";
import UsecaseItem from "@/components/common_presentational/UsecaseItem.vue";
export default defineComponent({
  name: "TalkTicketScreen",
  components: {
    UsecaseItem,
  },
  setup() {
    return;
  },
});
