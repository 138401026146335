export type FanArgs = {
  birth: string;
  annotation_id: string;
  display_name: string;
  image_uri: string;
  uuid: string;
  phone_number: string;
  created_at: number;
  updated_at: number;
};

export default class Fan {
  birth: string;
  annotation_id: string;
  display_name: string;
  image_uri: string;
  uuid: string;
  phone_number: string;
  created_at: number;
  updated_at: number;

  constructor(f: FanArgs) {
    this.birth = f.birth;
    this.annotation_id = f.annotation_id;
    this.display_name = f.display_name;
    this.image_uri = f.image_uri;
    this.phone_number = f.phone_number;
    this.uuid = f.uuid;
    this.created_at = f.created_at;
    this.updated_at = f.updated_at;
  }
}
