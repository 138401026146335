import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// GetTalents
import GetNowTalents from "@/components/container/GetNowTalentsContainer";
import GetFutureTalents from "@/components/container/GetFutureTalentsContainer";
import GetPickUpTalents from "@/components/container/GetPickUpTalentsContainer";
import GetAllTalents from "@/components/container/GetAllTalentsContainer";
// top
import TopScreen from "@/components/presentational/top/TopScreen.vue";
import TalkNowTalentsScreen from "@/components/presentational/top/TalkNowTalents.vue";
import TalkFutureTalentsScreen from "@/components/presentational/top/TalkFutureTalents.vue";
import PickUpTalentsScreen from "@/components/presentational/top/PickUpTalents.vue";
import AllTalentsScreen from "@/components/presentational/top/AllTalents.vue";
// talent page
import TalentInfoScreen from "@/components/presentational/talent/TalentInfoScreen.vue";
// login
import SignIn from "@/components/container/SignInContainer";
import SignInScreen from "@/components/presentational/SignIn.vue";
import NotFoundScreen from "@/components/presentational/NotFound.vue";
// mypage
import ShopScreen from "@/components/presentational/Shop.vue";
import Payment from "@/components/presentational/Payment.vue";
import PaymentContainer from "@/components/container/PaymentContainer";
import ShopContainer from "@/components/container/ShopContainer";
// ranking
import Ranking from "@/components/presentational/Ranking.vue";
import GetRanking from "@/components/container/GetRankingContainer";
// event
import EventScreen from "@/components/presentational/event/EventScreen.vue";
import ModiScreen from "@/components/presentational/event/modi/ModiScreen.vue";
import TalkTicketScreen from "@/components/presentational/event/modi/TalkTicketScreen.vue";
import CallScreen from "@/components/presentational/event/call/CallScreen.vue";
import UenoScreen from "@/components/presentational/event/ueno/UenoScreen.vue";
import MovieScreen from "@/components/presentational/event/movie/MovieScreen.vue";
import LiveProScreen from "@/components/presentational/event/livepro/LiveProScreen.vue";
import FirstAnniversaryForTalentScreen from "@/components/presentational/event/first_anniversary/FirstAnniversaryForTalentScreen.vue";
import FirstAnniversaryForFanScreen from "@/components/presentational/event/first_anniversary/FirstAnniversaryForFanScreen.vue";
import RainySeasonScreen from "@/components/presentational/event/rainy_season_2206/RainySeasonScreen.vue";
import YoungkingScreen from "@/components/presentational/event/youngking/YoungkingScreen.vue";
import CrossFMScreen from "@/components/presentational/event/cross_fm/CrossFMScreen.vue";
import Winter2212Screen from "@/components/presentational/event/winter_2212/Winter2212Screen.vue";

// office
import OfficeScreen from "@/components/presentational/office/OfficeScreen.vue";
import BarbieBarbieScreen from "@/components/presentational/office/KPoint/BarbieBarbieScreen.vue";
import ModokashiMarketsScreen from "@/components/presentational/office/KPoint/ModokashiMarketsScreen.vue";
import ReverseTokyoScreen from "@/components/presentational/office/Alphabet/ReverseTokyoScreen.vue";
import AkanezoranoPlanetariumScreen from "@/components/presentational/office/MILENIA/AkanezoranoPlanetariumScreen.vue";
import AozoranoPlanetariumScreen from "@/components/presentational/office/MILENIA/AozoranoPlanetariumScreen.vue";
import HimawariScreen from "@/components/presentational/office/MILENIA/HimawariScreen.vue";
import BashTheLimitScreen from "@/components/presentational/office/MILENIA/BashTheLimitScreen.vue";
import Log1nScreen from "@/components/presentational/office/Origami/Log1nScreen.vue";
import HimitsuheikiScreen from "@/components/presentational/office/Himitsuheiki/HimitsuheikiScreen.vue";
import Studio15Screen from "@/components/presentational/office/Studio15/Studio15Screen.vue";
import MeviusStoryScreen from "@/components/presentational/office/Makino/MeviusStoryScreen.vue";
// other
import ServerErrorScreen from "@/components/presentational/ServerError.vue";
import EventRankingScreen from "@/components/presentational/event/EventRankingScreen.vue";
import GetEventContainer from "@/components/container/GetEventContainer";
import TermsOfUse from "@/components/presentational/TermsOfUse.vue";
import Question from "@/components/presentational/Question.vue";
import ErrorsInformation from "@/components/presentational/ErrorsInformation.vue";
import ErrorsInformationFan from "@/components/presentational/ErrorsInformationFan.vue";
import AppPurchasePriceRevision from "@/components/presentational/other/AppPurchasePriceRevision.vue";

const routes: Array<RouteRecordRaw> = [
  // top
  {
    path: "/",
    name: "TopScreen",
    component: TopScreen,
  },
  {
    path: "/talent/:talent_id",
    name: "TalentInfoScreen",
    component: TalentInfoScreen,
  },
  {
    path: "/talents/talk-now",
    name: "TalkNowTalentsScreen",
    component: GetNowTalents(TalkNowTalentsScreen),
  },
  {
    path: "/talents/talk-future",
    name: "TalkFutureTalentsScreen",
    component: GetFutureTalents(TalkFutureTalentsScreen),
  },
  {
    path: "/talents/pick-up",
    name: "PickUpTalentsScreen",
    component: GetPickUpTalents(PickUpTalentsScreen),
  },
  {
    path: "/talents/all",
    name: "AllTalentsScreen",
    component: GetAllTalents(AllTalentsScreen),
  },
  {
    path: "/shop",
    name: "ShopScreen",
    component: ShopContainer(ShopScreen),
  },
  {
    path: "/ranking",
    name: "Ranking",
    component: GetRanking(Ranking),
  },
  {
    path: "/sign_in",
    name: "SignInScreen",
    component: SignIn(SignInScreen),
    beforeEnter(to, from, next) {
      if (window.innerWidth <= 768) {
        next();
      } else {
        next("/404");
      }
    },
  },
  {
    path: "/payment",
    name: "PaymentScreen",
    component: PaymentContainer(Payment),
    props: true,
  },
  {
    path: "/event",
    name: "EventScreen",
    component: EventScreen,
    children: [
      {
        path: "ranking/:event_path",
        name: "EventScreen",
        component: GetEventContainer(EventRankingScreen),
      },
      {
        path: "2110_modi",
        name: "ModiScreen",
        component: ModiScreen,
      },
      {
        path: "211105_call",
        name: "CallScreen",
        component: CallScreen,
      },
      {
        path: "2112_ueno",
        name: "UenoScreen",
        component: UenoScreen,
      },
      {
        path: "2201_kaiki",
        name: "MovieScreen",
        component: MovieScreen,
      },
      {
        path: "livepro",
        name: "LiveProScreen",
        component: LiveProScreen,
      },
      {
        path: "2203_cross_fm",
        name: "CrossFMScreen",
        component: CrossFMScreen,
      },
      {
        path: "2205_first_anniversary_1651330800",
        name: "FirstAnniversaryForTalentScreen",
        component: FirstAnniversaryForTalentScreen,
      },
      {
        path: "2205_first_anniversary",
        name: "FirstAnniversaryForFanScreen",
        component: FirstAnniversaryForFanScreen,
      },
      {
        path: "2206_rainy_season",
        name: "RainySeasonScreen",
        component: RainySeasonScreen,
      },
      {
        path: "2209_yangking",
        name: "YounggkingScreen",
        component: YoungkingScreen,
      },
      {
        path: "2212_winter",
        name: "Winter2212Screen",
        component:Winter2212Screen,
      },
    ],
  },
  {
    path: "/event/2110_modi/usage",
    name: "TalkTicketScreen",
    component: TalkTicketScreen,
  },
  {
    path: "/office",
    name: "OfficeScreen",
    component: OfficeScreen,
    children: [
      {
        path: "riku-ent.com/barbiebarbie",
        name: "BarbieBarbieScreen",
        component: BarbieBarbieScreen,
      },
      {
        path: "k-point/modokashi-markets",
        name: "ModokashiMarketsScreen",
        component: ModokashiMarketsScreen,
      },
      {
        path: "alphabet/reverse-tokyo",
        name: "ReverseTokyoScreen",
        component: ReverseTokyoScreen,
      },
      {
        path: "milenia/akanezora.plus",
        name: "AkanezoranoPlanetariumScreen",
        component: AkanezoranoPlanetariumScreen,
      },
      {
        path: "milenia/aozora.plus",
        name: "AozoranoPlanetariumScreen",
        component: AozoranoPlanetariumScreen,
      },
      {
        path: "milenia/himawari",
        name: "HimawariScreen",
        component: HimawariScreen,
      },
      {
        path: "milenia/bashthelimit",
        name: "BashTheLimitScreen",
        component: BashTheLimitScreen,
      },
      // {
      //   path: "live-pro/fruity",
      //   name: "FruityScreen",
      //   component: FruityScreen
      // },
      // {
      //   path: "live-pro/happy-sapporo",
      //   name: "HappyScreen",
      //   component: HappyScreen
      // },
      // {
      //   path: "live-pro/pelo-pelo-candy",
      //   name: "PeloPeloCandyScreen",
      //   component: PeloPeloCandyScreen,
      // },
      // {
      //   path: "live-pro/poison-palette",
      //   name: "PoisonPaletteScreen",
      //   component: PoisonPaletteScreen
      // },
      // {
      //   path: "live-pro/popping-smile",
      //   name: "PoppingSmileScreen",
      //   component: PoppingSmileScreen
      // },
      // {
      //   path: "live-pro/one-of-one-love",
      //   name: "OneOfOneLoveScreen",
      //   component: OneOfOneLoveScreen,
      // },
      // {
      //   path: "live-pro/geek-sapporo",
      //   name: "GeekScreen",
      //   component: GeekScreen
      // },
      {
        path: "origami/log1n",
        name: "Log1nScreen",
        component: Log1nScreen,
      },
      {
        path: "himitsuheiki",
        name: "HimitsuheikiScreen",
        component: HimitsuheikiScreen,
      },
      {
        path: "studio15",
        name: "Studio15Screen",
        component: Studio15Screen,
      },
    ],
  },
  {
    path: "/group",
    name: "GroupScreen",
    component: OfficeScreen,
    children: [
      {
        path: "mevius-story",
        name: "MeviusStoryScreen",
        component: MeviusStoryScreen,
      },
    ],
  },
  {
    path: "/500",
    name: "ServerErrorScreen",
    component: ServerErrorScreen,
  },
  {
    path: "/terms",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/question",
    name: "Question",
    component: Question,
  },
  {
    path: "/errors_information",
    name: "ErrorsInformation",
    component: ErrorsInformation,
  },
  {
    path: "/errors_information_fan",
    name: "ErrorsInformationFan",
    component: ErrorsInformationFan,
  },
  {
    path: "/price_revision",
    name: "AppPurchasePriceRevision",
    component: AppPurchasePriceRevision,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFoundScreen",
    component: NotFoundScreen,
  },
  // {
  //   path: "/theme",
  //   name: "Theme",
  //   component: () => import(/* webpackChunkName: "about" */ "@/ThemeScreen.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
