
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

export type DataType = {
  talentLists: {
    imageSrc: string;
    message: string;
    url: string;
  }[];
};
export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      talentLists: [
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0118_%E8%A5%BF%E9%87%8E%E5%86%AC%E8%8F%AF_ReverseTokyo7926%201.jpg",
          message: "西野冬華",
          url: "https://onlylive.jp/talent/dbcaa6cf-e0a9-47e0-aaf3-fc90eacc6a33",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0119_%E9%9D%92%E5%B1%B1%E3%81%AA%E3%81%AA%E3%81%93ReverseTokyo8044%201.jpg",
          message: "青山ななこ",
          url: "https://onlylive.jp/talent/a2fcd724-25e1-43c3-8b1f-0d0b54e4264f",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/alphabet/LA0120_nanase.jpg",
          message: "七瀬蘭",
          url: "https://onlylive.jp/talent/c21d60b9-4160-4d8a-85a9-97a0daf21f0a",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/alphabet/LA0175_kurusu.jpg",
          message: "來栖光伶",
          url: "https://onlylive.jp/talent/6e8fea40-a01c-4c1e-b770-dae2ade74c85",
        },
      ],
    });

    return {
      state
    }
  }
});
