
import { defineComponent, PropType, reactive } from "vue";

export type DataType = {
  isHover: boolean;
};
export default defineComponent({
  name: "TalentListCard",
  props: {
    imageSrc: {
      type: String as PropType<string>,
      required: true,
    },
    message: {
      type: String as PropType<string>,
      required: true,
      default: "message",
    },
    url: {
      type: String as PropType<string>,
      // required: true,
      default: "url",
    },
  },
  setup() {
    const state = reactive({
      isHover: false,
    })

    return {
      state
    }
  }
});
