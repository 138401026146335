
import { defineComponent, PropType, reactive } from "vue";
import { useRouter } from "vue-router";
import Talent from "@/entities/talent";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";
export default defineComponent({
  name: "OtherTalent",
  components: {
    Imgix
  },
  props: {
    otherTalents: {
      type: Array as PropType<Talent[]>,
      required: true,
    },
  },
  setup(prop) {
    const router = useRouter();
    const state = reactive({
      otheTalents: prop.otherTalents,
    });

    const imageSize: ImageSize = {
      sm: {
        width: 130,
        height: 173
      },
      md: {       
        width: 130,
        height: 173
      },
      lg: {
        width: 130,
        height: 173
      },
    }; 
    

    async function openTalent(talentID: string) {
      router.push({ name: 'TalentInfoScreen', params: { talent_id: talentID } });
    }

    return {
      state,
      openTalent,
      imageSize,
    };
  },
});
