
import { defineComponent, reactive } from "vue";
import TalentListCard from "@/components/common_presentational/TalentListCard.vue";

export type DataType = {
  talentLists: {
    imageSrc: string;
    message: string;
    url: string;
  }[];
};
export default defineComponent({
  name: "TalentList",
  components: {
    TalentListCard,
  },
  setup() {
    const state = reactive({
      talentLists: [
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0162_02_%E5%AE%87%E4%BD%90%E8%A6%8B%E3%82%A2%E3%83%B3%E3%83%8A.JPG",
          message: "宇佐見アンナ",
          url: "https://onlylive.jp/talent/6991712d-1e45-482d-a628-244459782ea8",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0161_01_%E8%81%96%E6%9C%88%E3%83%92%E3%83%A1%E3%83%8E.JPG",
          message: "聖月ヒメノ",
          url: "https://onlylive.jp/talent/63993f45-8303-4604-97a2-9b09502c3242",
        },
        {
          imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0163_01_%E6%9C%9D%E6%AF%94%E3%83%8A%E3%82%B3.JPG",
          message: "朝比ナコ",
          url: "https://onlylive.jp/talent/b5d32b86-ddc2-4d7a-bf90-986ed10d48c8",
        },
        // {
        //   imageSrc: "https://storage.googleapis.com/prod-barry-image/officies/milenia/LA0164_kitagawa_aozora.plus.jpg",
        //   message: "北川サクラ",
        //   url: "https://onlylive.jp/talent/99aa8a57-2368-448e-bc22-a5fe8eb0eaa6",
        // },
      ],
    });

    return {
      state
    }
  }
});
