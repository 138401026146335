export type AuthArgs = {
  fan_uuid: string;
  token: string;
};

export default class Auth {
  fan_uuid: string;
  token: string;

  constructor(f: AuthArgs) {
    this.fan_uuid = f.fan_uuid;
    this.token = f.token;
  }
}
