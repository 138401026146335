
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseRoundRectButton",
  props: {
    textInputTitle: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
    },
  },
});
