
import { defineComponent, onMounted, PropType, reactive } from "vue";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

export type DataType = {
  isHover: boolean;
};
export default defineComponent({
  name: "OfficeTalentListCard",
  components: {
    Imgix
  },
  props: {
    imageSrc: {
      type: String as PropType<string>,
      required: true,
    },
    displayName: {
      type: String as PropType<string>,
      required: true,
      default: "",
    },
    uuid: {
      type: String as PropType<string>,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      isHover: false,
      url: "",
    });
    const imageSize: ImageSize = {
      sm: {
        width: 125,
        height: 175,
      },
      md: {
        width: 150,
        height: 250,
      },
      lg: {
        width: 200,
        height: 300,
      },
    };

    async function setUrl() {
      state.url = `https://onlylive.jp/talent/${props.uuid}`
    }

    onMounted(async () => {
      try {
        await setUrl();
      } catch (e) {}
    });

    return {
      state,
      imageSize,
      setUrl
    }
  }
});
