
import { defineComponent } from "vue";
import Imgix from "@/components/atoms/Imgix.vue";

export default defineComponent({
  name: "RankingFirst",
  components: {
    Imgix,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  setup() {
    const imageSize = {
      sm: {
        width: 75,
        height: 75,
      },
      md: {
        width: 75,
        height: 75,
      },
      lg: {
        width: 95,
        height: 95,
      },
    };

    return {
      imageSize,
    };
  },
});
