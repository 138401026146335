import { defineComponent, h, ref, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import { CommunicationState } from "@/type";
import Fan from "@/entities/fan";
const GetFan = (WrappedComponent: any) =>
  defineComponent({
    name: "GetFanContainer",
    setup() {
      const communicationState = ref<CommunicationState>(1);
      const repositories = useRepositories();
      const fan = ref<Fan | undefined>(undefined);

      async function getFan(): Promise<Fan | undefined> {
        communicationState.value = 2;
        try {
          return await repositories.value.FanRepository.getFan();
        } catch (e) {
          repositories.value.SessionRepository.removeAuthInfo();
          return;
        } finally {
          communicationState.value = 1;
        }
      }

      onMounted(async () => {
        const value = await getFan();
        if (value == undefined) return;
        fan.value = value;
      });
      return {
        fan,
      };
    },
    render() {
      return h(WrappedComponent, {
        fan: this.fan,
      });
    },
  });
export default GetFan;
