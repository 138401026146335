
import { defineComponent } from "vue";
import Usecase from "@/components/common_presentational/Usecase.vue";

export default defineComponent({
  name: "CallScreen",
  components: {
    Usecase,
  },
});
