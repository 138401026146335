export type ProductArgs = {
  code: string;
  amount: number;
  point: number;
};

export class Product {
  code: string;
  amount: number;
  point: number;
  constructor(l: ProductArgs) {
    this.code = l.code;
    this.amount = l.amount;
    this.point = l.point;
  }
}
