
import { defineComponent } from "vue";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

export default defineComponent({
  name: "RankingUser",
  components: {
    Imgix
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const imageSize: ImageSize = {
        sm: {
          width: 50,
          height: 50,
        },
        md: {       
          width: 50,
          height: 50,
        },
        lg: {
          width: 50,
          height: 50,
        },
      }; 
    return {
      imageSize
    }
  }
});
