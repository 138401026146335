import { defineComponent, h, onMounted, reactive } from "vue";
import { useRepositories } from "@/infra/api/injects";
import { useRoute } from "vue-router";
import { Event } from "@/entities/event";
import { RankingUserInfo } from "@/entities/ranking";
const GetEventContainer = (WrappedComponent: any) =>
  defineComponent({
    name: "GetEventContainer",
    setup() {
      const state = reactive({
        event: {} as Event,
        ranking: [] as Array<RankingUserInfo>,
        communicationState: 1,
      });

      const repositories = useRepositories();

      async function getEvent(): Promise<Event> {
        const event_parameter = useRoute().params.event_path;
        state.communicationState = 2;

        try {
          return await repositories.value.EventRepository.getEvent(event_parameter as string);
        } catch (e) {
          throw e;
        } finally {
          state.communicationState = 1;
        }
      }

      async function getEventRanking(eventID: number): Promise<Array<RankingUserInfo>> {
        try {
          return await repositories.value.EventRepository.getEventRanking(eventID);
        } catch (e) {
          return [];
        } finally {
          state.communicationState = 1;
        }
      }

      onMounted(async () => {
        const event = await getEvent();
        const ranking = await getEventRanking(event.id);
        state.event = event;
        state.ranking = ranking;
      });
      return {
        state,
      };
    },
    render() {
      return h(WrappedComponent, {
        event: this.state.event,
        rankingUsers: this.state.ranking,
      });
    },
  });
export default GetEventContainer;
