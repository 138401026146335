
import { defineComponent, PropType, reactive } from "vue";
import { useRouter } from "vue-router";
// @ts-ignore
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay } from "swiper/core";
import Talent from "@/entities/talent";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

SwiperCore.use([Autoplay]);

export default defineComponent({
  name: "TalkNowOfTopScreen",
  components: {
    Swiper,
    SwiperSlide,
    Imgix,
  },

  props: {
    nowTalents: {
      type: Array as PropType<Talent[]>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const imageSize: ImageSize = {
      sm: {
        width: 162,
        height: 216,
      },
      md: {
        width: 160,
        height: 213,
      },
      lg: {
        width: 160,
        height: 213,
      },
    };

    const state = reactive({
      nowTalents: props.nowTalents,
      autoplayOptions: {
        disableOnInteraction: false,
      },
      breakpointOptions: {
        375: {
          slidesPerView: 2.255,
          spaceBetween: 12,
        },
        460: {
          slidesPerView: 2.8,
          spaceBetween: 12,
        },
        630: {
          slidesPerView: 4,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 4.2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5.77,
          spaceBetween: 20,
        },
      },
    });

    async function openTalent(talentID: string) {
      router.push({ name: "TalentInfoScreen", params: { talent_id: talentID } });
    }

    return {
      state,
      openTalent,
      imageSize,
    };
  },
});
