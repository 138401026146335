
import { defineComponent, onMounted, reactive } from "vue";
import LPRanking from "@/components/presentational/event/EventRankingScreen.vue"
import Ranking from "@/components/presentational/event/Ranking.vue";
import liveproRanking from "@/components/presentational/event/Ranking.vue";
import { RankingUserInfo } from "@/entities/ranking";
import { useRepositories } from "@/infra/api/injects";
import { Event } from "@/entities/event";

export default defineComponent({
  name: "LiveproScreen",
  components: {
    LPRanking,
    Ranking,
    liveproRanking,
  },
  setup() {
      const state = reactive({
        event: {} as Event,
        ranking: [] as Array<RankingUserInfo>,
        communicationState: 1,
      });

      const repositories = useRepositories();
      const startDate = new Date(new Date().getFullYear(),new Date().getMonth(), 1, 0, 0)
      startDate.setMonth(startDate.getMonth())
      const endDate = startDate
      endDate.setMonth(endDate.getMonth() + 1)
      endDate.setMilliseconds(endDate.getMilliseconds() - 1)

      async function getEvent(): Promise<Event> {
        const month = (startDate.getMonth() + 1).toString().padStart(2, "0")
        const event_parameter = `22${month}_livepro`
        state.communicationState = 2;

        try {
          return await repositories.value.EventRepository.getEvent(event_parameter);
        } catch (e) {
          throw e;
        } finally {
          state.communicationState = 1;
        }
      }

      async function getEventRanking(eventID: number): Promise<Array<RankingUserInfo>> {
        try {
          return await repositories.value.EventRepository.getEventRanking(eventID);
        } catch (e) {
          return [];
        } finally {
          state.communicationState = 1;
        }
      }

      onMounted(async () => {
        const event = await getEvent();
        const ranking = await getEventRanking(event.id);
        state.event = event;
        state.ranking = ranking;
      });
      return {
        state,
        startDate,
        endDate
      };
    }
});
