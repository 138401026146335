
import { defineComponent, reactive } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import AppDownloadModal from "@/components/common_presentational/AppDownloadModal.vue";
import Fan from "@/entities/fan";
import ProfileIconMaterial from "@/components/common_presentational/ProfileIcon.vue";
import SignInModalMaterial from "@/components/common_presentational/SignInModal.vue";
import SignInContainer from "@/components/container/SignInContainer";
import { useRouter } from "vue-router";
import GetFanContainer from "@/components/container/GetFanContainer";
import { useRepositories } from "@/infra/api/injects";
const SignInModal = SignInContainer(SignInModalMaterial);
const ProfileIcon = GetFanContainer(ProfileIconMaterial);
export default defineComponent({
  name: "Header",
  components: {
    BaseRoundRectButton,
    AppDownloadModal,
    ProfileIcon,
    SignInModal,
  },
  props: {
    fan: {
      type: Object as () => Fan,
      required: true,
      default: false,
    },
  },
  setup() {
    const state = reactive({
      isOpened: false,
      openedDropdown: false,
      isOpenModal: false,
      isOpenSignInModal: false,
      isLogined: false,
    });

    function openMenu() {
      state.isOpened = !state.isOpened;
      state.openedDropdown = false;
    }
    function openDropdown() {
      state.openedDropdown = !state.openedDropdown;
      state.isOpened = false;
    }
    function closeMenu() {
      state.openedDropdown = false;
      state.isOpened = false;
    }
    function openModal() {
      state.isOpenModal = true;
      closeMenu();
    }
    const router = useRouter();

    function toShop() {
      closeMenu();
      router.push({ name: "ShopScreen" });
    }
    const repo = useRepositories();
    async function logout() {
      await repo.value.SessionRepository.removeAuthInfo();
      location.reload();
    }
    async function checkLogin() {
      const auth = await repo.value.SessionRepository.getAuthInfo();
      if (auth) {
        state.isLogined = true;
      }
    }
    checkLogin();
    function openSignInModal() {
      if (window.innerWidth <= 400) {
        router.push({ name: "SignInScreen" });
      } else {
        // signInページを開いてる状態でモーダルを開けないようにする
        if (window.location.pathname === "/sign_in") return;
        state.isOpenSignInModal = true;
      }
    }
    function closeModal() {
      state.isOpenModal = false;
    }
    return {
      state,
      openMenu,
      openDropdown,
      closeMenu,
      openModal,
      closeModal,
      openSignInModal,
      logout,
      toShop,
    };
  },
});
