export const itemCodes = [
  ["item001", 300],
  ["item002", 500],
  ["item003", 750],
  ["item004", 1000],
  ["item005", 1250],
  ["item006", 1500],
  ["item007", 1750],
  ["item008", 2000],
  ["item009", 3000],
  ["item010", 4000],
  ["item011", 5000],
  ["item012", 6000],
  ["item013", 7000],
  ["item014", 8000],
  ["item015", 9000],
  ["item016", 10000],
]
