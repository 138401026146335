
import { defineComponent, PropType, reactive, ref, watch } from "vue";
// @ts-ignore
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { CarouselItem } from "./interface";

export default defineComponent({
  name: "AppDownloadModal",
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    features: {
      type: Object as PropType<CarouselItem[]>,
      required: true,
    },
  },
  setup(prop) {
    const carouselStyle = reactive({
      fade: false,
      arrows: true,
    });
    const setCarouselStyle = () => {
      if (window.outerWidth < 768) {
        carouselStyle.fade = true;
        carouselStyle.arrows = false;
      } else {
        carouselStyle.fade = false;
        carouselStyle.arrows = true;
      }
    };
    const items =  ref<CarouselItem[]>();
    watch(
      () => prop.features,
      () => {
        items.value = prop.features
      }
    );
    setCarouselStyle();
    window.addEventListener("resize", setCarouselStyle);
    return {
      items,
      carouselStyle,
    };
  },
});
