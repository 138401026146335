import { defineComponent, h, reactive, onMounted } from "vue";
import { useRepositories } from "@/infra/api/injects";
import Talent from "@/entities/talent"

const GetPickUpTalents = (WrapperComponent: any) =>
  defineComponent({
    name: "GetNowTalentsContainer",
    setup() {
      const state = reactive({
        pickupTalents: [] as Talent[],
        pickupChoicies: [
          {
            displayName: "藤田真澄",
            imageUrl: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0067_01_%E8%97%A4%E7%94%B0%E7%9C%9F%E6%BE%84.jpeg",
            talentID: "32442ce1-4ebb-4336-aacb-23453ff411a0",
            genre: "タレント"
          },
           {
            displayName: "ひまわり",
            imageUrl: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0160_%E3%81%B2%E3%81%BE%E3%82%8F%E3%82%8A.JPG",
            talentID: "8a8ae7eb-aab9-4673-86c4-6848bf4e7997",
            genre: "TikToker"
          },
          {
            displayName: "朝比ナコ",
            imageUrl: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0163_01_%E6%9C%9D%E6%AF%94%E3%83%8A%E3%82%B3.JPG",
            talentID: "b5d32b86-ddc2-4d7a-bf90-986ed10d48c8",
            genre: "アイドル"
          },
          {
            displayName: "まーちん",
            imageUrl: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0033_01_%E3%81%BE%E3%83%BC%E3%81%A1%E3%82%93.jpeg",
            talentID: "a236a824-aa6e-4936-a005-0702382f1efa",
            genre: "YouTuber"
          }
        ],
        nextPageTokenOfTopics: ""
      });

      const repositories = useRepositories()

      async function getTopicsTalents() {
        const talents = await repositories.value.TalentRepository.getTalentsByTopic(state.nextPageTokenOfTopics)
        state.nextPageTokenOfTopics = talents[1]
        // 固定している４名のtalentIDを代入
        // const removeTalentIDs = [
        //   state.pickupChoicies[0].talentID,
        //   state.pickupChoicies[1].talentID,
        //   state.pickupChoicies[2].talentID,
        //   state.pickupChoicies[3].talentID,
        // ];
        // 代入されたIDのindexをfindIndexで一つずつ検索し、全体からspliceで削除する
        // removeTalentIDs.forEach((id) => {
        //   const index = talents[0].findIndex((v) => v.talentID == id);
        //   talents[0].splice(index, 1)
        // })
        Array.prototype.push.apply(state.pickupTalents, talents[0])
        if(state.nextPageTokenOfTopics != "") {
          await getTopicsTalents()
        }
      }

      onMounted(async () => {
        await getTopicsTalents()
      })

      return {
        state,
      };
    },
    render() {
      return h(
        WrapperComponent, {
          pickupTalents: this.state.pickupTalents,
          // pickupChoicies: this.state.pickupChoicies,
        }
      );
    },
  });
export default GetPickUpTalents;