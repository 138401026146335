
import { defineComponent, PropType, reactive } from "vue";
import Carousel from "@/components/common_presentational/CarouselSlider.vue";
import { CarouselItem } from "@/components/common_presentational/interface";
export default defineComponent({
  name: "Features",
  components: {
    Carousel,
  },
  props: {
    features: {
      type: Array as PropType<CarouselItem[]>,
      required: true,
    },
  },
  setup(prop) {
    const state = reactive({
      features: prop.features,
    });
    return {
      state,
    }
  }
});
