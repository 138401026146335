
import { defineComponent, PropType, reactive, watch } from "vue";
import LPRankingUser from "@/components/common_presentational/LPRankingUser.vue";
import RankingFirst from "@/components/common_presentational/rankings/RankingFirst.vue";
import RankingSecond from "@/components/common_presentational/rankings/RankingSecond.vue";
import RankingThird from "@/components/common_presentational/rankings/RankingThird.vue";
import { RankingUserInfo } from "@/entities/ranking";
import { Event } from "@/entities/event";
import Talent from "@/entities/talent";

export default defineComponent({
  name: "LPRanking",
  components: {
    LPRankingUser,
    RankingFirst,
    RankingSecond,
    RankingThird,
  },
  props: {
    rankingUsers: {
      type: Array as PropType<RankingUserInfo[]>,
      required: true,
    },
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      date: "",
      rankingUsers: [] as Array<RankingUserInfo>,
    });

    watch(
      () => props.event,
      () => {
        const sd = props.event.startDate;
        const ed = props.event.endDate;
        const startDate = `${sd.getFullYear()}年${sd.getMonth() + 1}月${sd.getDate()}日`;

        const hasDiffMoreAYear = sd.getFullYear() != ed.getFullYear();
        const endDate = `${hasDiffMoreAYear ? ed.getFullYear() + "年" : ""}${ed.getMonth() + 1}月${ed.getDate()}日`;

        state.date = `${startDate} ~ ${endDate}`;
      }
    );
    watch(
      () => props.rankingUsers,
      () => {
        for (let i = 0; i < 10; i++) {
          if (i + 1 <= props.rankingUsers.length) {
            state.rankingUsers.push(props.rankingUsers[i]);
          } else {
            state.rankingUsers.push(
              new RankingUserInfo({
                influencer: {
                  influencer: new Talent({
                    displayName: "",
                    eventDate: 0,
                    genre: [],
                    images: [],
                    introduction: "",
                    talentID: "",
                    liveStatus: 0,
                  }),
                  points: 0,
                }, 
                
              })
            );
          }
        }
        state.isLoading = false;
      }
    );

    return {
      state,
    };
  },
});
