export type FeatureArgs = {
  imageSrc: string;
  url: string;
};

export default class Feature {
  imageSrc: string;
  url: string;

  constructor(f: FeatureArgs) {
    this.imageSrc = f.imageSrc,
    this.url = f.url
  }
}