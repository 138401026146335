
import { defineComponent } from "vue";import TalentList from "./AozoranoPlanetariumTalentList.vue";
import Usecase from "@/components/common_presentational/Usecase.vue";

export default defineComponent({
  name: "StemScreen",
  components: {
    TalentList,
    Usecase
  },
});
