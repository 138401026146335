import Ranking from "@/entities/ranking";
import { useRepositories } from "@/infra/api/injects";
import { defineComponent, h, onMounted, reactive, ref } from "vue";
import { CommunicationState } from "@/type";

const GetRanking = (WrappedComponent: any) =>
  defineComponent({
    name: "GetRankingContainer",
    setup() {
      const state = reactive({
        rankings: {} as Ranking,
      });

      const communicationState = ref<CommunicationState>(1);
      const repositories = useRepositories();

      async function getRanking() {
        state.rankings = await repositories.value.TalentRepository.getRanking();
      }

      onMounted(async () => {
        communicationState.value = 2;
        try {
          await getRanking();
        } catch (e) {
          console.log(e);
        } finally {
          communicationState.value = 1;
        }
      });

      return {
        state,
        communicationState,
      };
    },

    render() {
      return h(WrappedComponent, {
        ranking: this.state.rankings,
        communicationState: this.communicationState,
      });
    },
  });
export default GetRanking;
