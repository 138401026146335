
  import { defineComponent, reactive } from "vue";
  import TalentListCard from "@/components/common_presentational/TalentListCard.vue"

  export type DataType = {
    talentLists: {
      imageSrc: string;
      message: string;
      url: string;
    }[];
  };

  export default defineComponent({
    name: "Log1nTalentList",
    components: {
      TalentListCard
    },
    setup() {
      const state = reactive({
        talentLists: [
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0181_%E6%9D%A5%E4%B8%96%E6%9C%80%E3%81%84%E3%81%AE%E3%82%8B.001.jpeg",
            message: "来世最いのる",
            url: "https://onlylive.jp/talent/84b02bb6-a99f-46e1-be2c-b5268c3604a7",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0182_%E5%86%8D%E6%97%A5%E3%81%82%E3%81%8A.001.jpeg",
            message: "再日あお",
            url: "https://onlylive.jp/talent/a53e1caa-5459-4485-b129-8c75c8da8a10",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0183_%E8%A6%AA%E6%84%9B%E3%81%AA%E3%82%8B.001.jpeg",
            message: "親愛なる",
            url: "https://onlylive.jp/talent/bba8e44f-8d96-4505-8f0c-6613eca77479",
          },
          {
            imageSrc: "https://storage.googleapis.com/prod-barry-image/TalentImages/LA0184_%E4%BA%94%E8%89%B2%E4%B9%83%E3%81%84%E3%81%A8.001.jpeg",
            message: "五色乃いと",
            url: "https://onlylive.jp/talent/72708e00-058f-4309-a3a5-132b1ae30ab1",
          },
        ]
      })
      return {
        state,
      }
    }
  })
