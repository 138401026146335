
import { defineComponent, reactive, ref, watch } from "vue";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import TextField from "@/components/atoms/TextField.vue";
import Modal from "@/components/common_presentational/Modal.vue";
import { SignInInfoArgs } from "@/entities/sign_in";
import { LocalizationError } from "@/error/error";
import { CommunicationState } from "@/type";
export default defineComponent({
  name: "SignIn",
  components: {
    BaseRoundRectButton,
    TextField,
    Modal,
  },
  props: {
    localizationError: {
      type: Object as () => LocalizationError | null,
      default: null,
      required: false,
    },
    communicationState: {
      type: Object as () => CommunicationState,
      default: 1,
      required: true,
    },
  },
  emits: {
    signIn: (request: SignInInfoArgs): boolean => {
      return true;
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      password: "",
      phoneNumber: "",
    });
    const isLoading = ref<boolean>(false);
    function emitSignIn() {
      emit("signIn", { phone_number: state.phoneNumber, password: state.password } as SignInInfoArgs);
    }
    watch(
      () => props.communicationState,
      () => {
        if (props.communicationState === 2) {
          isLoading.value = true;
        } else {
          isLoading.value = false;
        }
      }
    );
    return {
      state,
      isLoading,
      emitSignIn,
    };
  },
});
