
import { defineComponent } from "vue";
import LPRanking from "@/components/presentational/event/ueno/UenoRanking.vue";

export default defineComponent({
  name: "UenoScreen",
  components: {
    LPRanking,
  },
  setup() {
    return {};
  },
});
