
import { defineComponent, PropType, reactive, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import SignInContainer from "@/components/container/SignInContainer";
import SignInModalMaterial from "@/components/common_presentational/SignInModal.vue";
import Dialog from "@/components/atoms/Dialog.vue";
import AppDownloadModal from "@/components/common_presentational/AppDownloadModal.vue";
import { LiveStreamMethodType } from "@/entities/fan_meeting";
import Loading from "@/components/atoms/Loading.vue";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";
import Talent from "@/entities/talent";
import Fan from "@/entities/fan";
import { itemCodes } from "@/util/item_code"
import { FanMeetingAndReserved } from "@/entities/fan_meeting_and_reserved";
import { CommunicationState } from "@/type";

const SignInModal = SignInContainer(SignInModalMaterial);

export default defineComponent({
  name: "TalentInfo",
  components: {
    BaseRoundRectButton,
    AppDownloadModal,
    Imgix,
    Dialog,
    SignInModal,
    Loading
  },
  props: {
    talent: {
      type: Object as PropType<Talent>,
      required: true,
    },
    fan: {
      type: Object as PropType<Fan | undefined>,
      required: true,
    },
    nowFanMeeting: {
      type: Object as PropType<FanMeetingAndReserved | undefined>,
      required: true,
    },
    schedule: {
      type: Array as PropType<FanMeetingAndReserved[]>,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    communicationState: {
      type: Object as () => CommunicationState,
      default: 1,
      required: true,
    },
  },
  emits: {
    createReservation(fanMeetingId: number) {
      if (fanMeetingId != null) return true;
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      isActive: props.schedule.length > 0,
      isOpenModal: false,
      isOpenSignInModal: false,
      isOpenReservationModal: false,
    });

    const router = useRouter()

    function pushShop() {
      router.push("/shop")
    }

    function displayTime(time: number) {
      const min = Math.floor(time / 60);
      const sec = time % 60;

      let displayTime = ""

      if (1 <= min) {
        displayTime = min + "分";
      }

      if (1 <= sec) {
        displayTime += sec + "秒"
      }


      return displayTime
    }

    const isLoading = ref<boolean>(false);

    watch(
      () => props.communicationState,
      () => {
        if (props.communicationState === CommunicationState.doing) {
          isLoading.value = true;
        } else {
          isLoading.value = false;
        }
      }
    );

    const selectedFanMeeting = ref<FanMeetingAndReserved>()

    const coinNum = computed(() => {
      return itemCodes.find((e) => e[0] === selectedFanMeeting.value?.fanMeeting.itemCode)?.[1]
    })

    function reserveText(fanMeetingReserved: FanMeetingAndReserved) {
      if (fanMeetingReserved.isReserved) {
        return "予約済み"
      }
      if (props.balance < (fanMeetingReserved.fanMeeting.chekiPrice + (coinNum.value as number))) {
        return "残高が不足しています"
      }
      return "予約する"
    }

    function createReservation() {
      emit("createReservation", selectedFanMeeting.value?.fanMeeting.id ?? 0);
    }

    const talentID = useRoute().params.talent_id;

    const imageSize: ImageSize = {
      sm: {
        width: window.innerWidth,
        height: 500,
      },
      md: {
        width: 300,
        height: 400,
      },
      lg: {
        width: 300,
        height: 400,
      },
    };

    const iconImageSize: ImageSize = {
      sm: {
        width: 25,
        height: 25,
      },
      md: {
        width: 25,
        height: 25,
      },
      lg: {
        width: 25,
        height: 25,
      },
    };

    // メソッド
    function active_intro() {
      state.isActive = true;
    }
    function active_schedule() {
      state.isActive = false;
    }
    function openModal() {
      state.isOpenModal = true;
    }
    function closeModal() {
      state.isOpenModal = false;
    }
    function openReservationDialog(fanMeeting: FanMeetingAndReserved) {
      if (props.fan === undefined) {
        state.isOpenSignInModal = true
        return
      }
      state.isOpenReservationModal = true
      selectedFanMeeting.value = fanMeeting
    }

    function openIOSdeeplink() {
      window.open(`https://${process.env.VUE_APP_DEEPLINK_BASEHOST}/share?talentID=${talentID}`);
    }

    function openAndroiddeeplink() {
      window.open(`https://onlylive.page.link/?link=https://onlylive.page.link?talentID%3D${talentID}%26mode%3DsignIn%26oobCode%3Dc8IUL5k56atoHP2jrk9l7YjAAa17Z5NhBY_CQjPP0oEAAAGGz7rF3g%26continueUrl%3Dhttps://onlylive.page.link/share%26lang%3Dja&apn=com.ILEO.onlylive&amv&afl=https://onlylive.page.link?talentID%3D1c2a3e86-f7bf-486d-b7f3-a18238e915e5%26mode%3DsignIn%26oobCode%3Dc8IUL5k56atoHP2jrk9l7YjAAa17Z5NhBY_CQjPP0oEAAAGGz7rF3g%26continueUrl%3Dhttps://onlylive.page.link/share%26lang%3Dja`);
    }

    const iosDeepLink = `https://${process.env.VUE_APP_DEEPLINK_BASEHOST}/share?talentID=${talentID}`;
    const androidDeepLink = `https://onlylive.page.link/?link=https://onlylive.page.link?talentID%3D${talentID}%26mode%3DsignIn%26oobCode%3Dc8IUL5k56atoHP2jrk9l7YjAAa17Z5NhBY_CQjPP0oEAAAGGz7rF3g%26continueUrl%3Dhttps://onlylive.page.link/share%26lang%3Dja&apn=com.ILEO.onlylive&amv&afl=https://onlylive.page.link?talentID%3D1c2a3e86-f7bf-486d-b7f3-a18238e915e5%26mode%3DsignIn%26oobCode%3Dc8IUL5k56atoHP2jrk9l7YjAAa17Z5NhBY_CQjPP0oEAAAGGz7rF3g%26continueUrl%3Dhttps://onlylive.page.link/share%26lang%3Dja`

    return {
      state,
      active_intro,
      active_schedule,
      openModal,
      closeModal,
      imageSize,
      iosDeepLink,
      androidDeepLink,
      createReservation,
      openReservationDialog,
      iconImageSize,
      selectedFanMeeting,
      LiveStreamMethodType,
      itemCodes,
      coinNum,
      pushShop,
      displayTime,
      isLoading,
      reserveText,
      openIOSdeeplink,
      openAndroiddeeplink
    };
  },
});
