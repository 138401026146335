
import { defineComponent, PropType } from "vue";
import { useRouter } from "vue-router";
import BaseRoundRectButton from "@/components/atoms/BaseRoundRectButton.vue";
import Talent from "@/entities/talent";
import Imgix, { ImageSize } from "@/components/atoms/Imgix.vue";

interface PickUpChoicies {
  displayName: string;
  imageUrl: string;
  talentID: string;
  genre: string;
}

export default defineComponent({
  name: "PickUpOfTopScreen",
  components: {
    BaseRoundRectButton,
    Imgix,
  },

  props: {
    pickupTalents: {
      type: Array as PropType<Talent[]>,
      required: true,
    },
    pickupChoicies: {
      type: Array as PropType<PickUpChoicies[]>,
      required: true,
    },
  },

  setup() {
    const router = useRouter();

    const imageSize: ImageSize = {
      sm: {
        width: 162,
        height: 216,
      },
      md: {
        width: 160,
        height: 213,
      },
      lg: {
        width: 160,
        height: 213,
      },
    };

    async function openTalent(talentID: string) {
      router.push({ name: "TalentInfoScreen", params: { talent_id: talentID } });
    }

    return {
      openTalent,
      imageSize,
    };
  },
});
